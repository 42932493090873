import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup
} from "reactstrap";
import axios from "axios";
import moment from 'moment';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function PreguntasFioriCreate() {
  const { user } = useContext(AuthContext);
  const URL_PREGUNTAS_FIORI = process.env.REACT_APP_URL_PREGUNTAS_FIORI;
  const URL_RESPUESTAS_FIORI = process.env.REACT_APP_URL_RESPUESTAS_FIORI;
  const URL_VECINOS_FIORI = process.env.REACT_APP_URL_VECINOS_FIORI;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [vecinosFiori, setVecinosFiori] = useState([]);
  const [pregunta, setPregunta] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  useEffect(() => {
    axios
      .get(URL_VECINOS_FIORI, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVecinosFiori = response.data;
        setVecinosFiori(allVecinosFiori);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const savePreguntasFiori = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalVecinos = 24;
    axios
      .post(
        URL_PREGUNTAS_FIORI,
        {
          pregunta,
          is_active:"Si",
          mostrar:"Si"
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((data) => {
        vecinosFiori.map((a) => {
          axios
            .post(
              URL_RESPUESTAS_FIORI,
              {
                fecha: hoy,
                respuesta: "No ha votado",
                preguntasFiori: data.data._id,
                vecinosFiori: a._id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              totalVecinos = totalVecinos - 1;
              if (totalVecinos == 0) {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Crear Pregunta Fiori",
                    detalle: pregunta,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });

          //Cierra Map
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <h3 align="center">Crea una nueva Pregunta</h3>
        <Form onSubmit={savePreguntasFiori}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="exampleEmail" className="mr-sm-2">
              Pregunta
            </Label>
            <Input
              type="text"
              placeholder="Pregunta"
              value={pregunta}
              required
              onChange={(e) => {
                setPregunta(e.target.value);
              }}
            />
          </FormGroup>
          <Row className="container">
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button color="danger" href="/MenuFiori">
              Regresar
            </Button>
          </Row>
        </Form>
      </div>
      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
                  <ModalHeader toggle={toggleProgreso}>
                    <h4>Progreso</h4>
                  </ModalHeader>
                  <ModalBody>
                    Estamos enviando los correos, este proceso puede tardar
                    varios minutos.
                    <br />
                    Por favor no cierre ni refresque su navegador.
                    <br />
                    <div className="divPadre2">
                      <div className="divHijo2">
                        <Spinner
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#232B41",
                          }}
                        />
                        <Spinner
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#232B41",
                          }}
                        />
                        <Spinner
                          style={{
                            width: "3rem",
                            height: "3rem",
                            color: "#232B41",
                          }}
                        />
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
    </>
  );
}

export default PreguntasFioriCreate;
