import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button } from "reactstrap";
import Header from "../../layout/Header/Header";

function BotonesReportes() {
  const { user } = useContext(AuthContext);
  return (
    <>
      <Header />
      <br />
      <br />
      {user.usuarios_permisos ? (
      <div className="container">
            <Row>
            <Col md={4} align="center">
            {user.usuarios_permisos ?(
              <Button
                href="/GraficaVentasMensual"
                className="botonesMenu"
                color="success"
              >
                <i class="far fa-list-alt fa-7x"></i>
                <br />
                <br />
                Venta Mensual
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="far fa-list-alt fa-7x"></i>
            <br />
            <br />
            Venta Mensual
          </Button> }
            </Col>
            <Col md={4} align="center">
            {user.usuarios_permisos ?(
              <Button
                href="/GraficaVentasAnual"
                className="botonesMenu"
                color="success"
              >
                <i class="far fa-list-alt fa-7x"></i>
                <br />
                <br />
                Venta Anual
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="far fa-list-alt fa-7x"></i>
            <br />
            <br />
            Venta Anual
          </Button> }
            </Col>

            <Col md={4} align="center">
            {user.usuarios_permisos ?(
              <Button
                href="/GraficaAbonosMensual"
                className="botonesMenu"
                color="success"
              >
                <i class="fas fa-dollar-sign fa-7x"></i>
                <br />
                <br />
                Abonos
              </Button>
            ): <Button
            disabled
            className="botonesMenu"
            color="success"
          >
            <i class="fas fa-dollar-sign fa-7x"></i>
            <br />
            <br />
            Abonos
          </Button> }
            </Col>
          
            </Row>
      </div>
      ) : undefined}
    </>
  );
}

export default BotonesReportes;
