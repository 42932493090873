import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function PorRetirarCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SOCIOS = process.env.REACT_APP_URL_SOCIOS;
  const URL_POR_RETIRAR = process.env.REACT_APP_URL_POR_RETIRAR;
  const URL_POR_RETIRAR_SOCIOS = process.env.REACT_APP_URL_POR_RETIRAR_SOCIOS;
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [socios, setSocios] = useState([]);
  const [fecha, setFecha] = useState(hoy);


  const [observaciones, setObservaciones] = useState("NA");

  const [validaBoton, setValidaBoton] = useState(true);
  const [total, setTotal] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useMemo(() => {
    axios
      .get(URL_SOCIOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSocios = response.data;
        let array = allSocios
          .map((a) => {
            return {
              _id: a._id,
              name: a.name,
              participacion: a.participacion,
              importe: 0,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(array);

        setSocios(data);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  function saveRetiro() {


    Swal.fire({
      title: "Estas seguro?",
      text: "Se registrará el retiro",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Retirar!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();
        let totalMails = socios.length;

        axios
          .post(
            URL_POR_RETIRAR,
            {
              fecha,
              total: total,
              observaciones,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((dataRetiro) => {

            socios.map((a) => {
              axios
                .post(
                  `${URL_POR_RETIRAR_SOCIOS}`,
                  {
                    fecha,
                    socios: a._id,
                    porRetirar: dataRetiro.data._id,
                    importe: a.importe,
                    participacion: a.participacion,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                 

                  totalMails = totalMails - 1;
                  if (totalMails == 0) {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    // footer: `${error.response.data}`,
                  });
                  console.log(error);
                });

              //Termina el map
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              // footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }



  function jalaTotal(e) {
  
    setTotal(e);

    const newSocios = socios.map((i) => {
      i.importe = (i.participacion * e) / 100;
      return i;
    });
    setSocios(newSocios);

  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_usuarios ? (
        <div className="card container col-sm-11">
          <h3 align="center">Captura un Retiro</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={2}>
                  <Label>Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>

               

                <Col md={2}>
                  <Label>Total</Label>
                  <Input
                    type="number"
                    value={total}
                    required
                    onChange={(e) => {
                      jalaTotal(e.target.value);
                    }}
                  />
                </Col>

              </Row>

              <Row>
              <Col md={6}>
                  <Label>Observaciones</Label>
                  <Input
                    type="text"
                    value={observaciones}
                    required
                    onChange={(e) => {
                      setObservaciones(e.target.value);
                    }}
                  />
                </Col>
              </Row>

              <br />
                <Row>
                  {/* Tabla CargosContenedor */}
                  <br />
                  <br />
                  <Table striped borderless>
                    <thead>
                      <tr>
                        <th>Socio</th>
                        <th>Participacion</th>
                        <th>Por Retirar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {socios.map((a) => (
                        // <div key={a.id}>
                        <tr>
                          <td>{a.name}</td>
                          <td>{a.participacion}%</td>
                          <td>
                            {"$" +
                              new Intl.NumberFormat("en-US").format(
                                a.importe
                              )}
                          </td>
                          
                        </tr>
                        // </div>
                      ))}
                      <tr>
                        <td></td>
                        <td className="negrita">Total</td>
                        <td className="negrita">
                          {"$" +
                            new Intl.NumberFormat("en-US").format(
                              total
                            )}
                        </td>
                       
                      </tr>
                    </tbody>
                    <br />
                  </Table>

                  {/* Termina tabla CargosContenedor */}
                </Row>
            </FormGroup>
            <br />
            <Row>
              <Button className="btn btn-success" onClick={saveRetiro}>
                {" "}
                Registrar
              </Button>

              <Button
                href="/PorRetirar"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              Estamos enviando los correos, este proceso puede tardar varios
              minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              <br />
              <div className="divPadre2">
                <div className="divHijo2">
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "3rem",
                      height: "3rem",
                      color: "#232B41",
                    }}
                  />
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default PorRetirarCreate;
