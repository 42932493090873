import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Button,
  Row,
  Table,
  Badge,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  Input,
  Label,
  Col,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function RespuestasFioriCreate() {
  // const { user } = useContext(AuthContext);
  const URL_PREGUNTAS_FIORI = process.env.REACT_APP_URL_PREGUNTAS_FIORI;
  const URL_RESPUESTAS_FIORI = process.env.REACT_APP_URL_RESPUESTAS_FIORI;
  const URL_VECINOS_FIORI = process.env.REACT_APP_URL_VECINOS_FIORI;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [vecinosFiori, setVecinosFiori] = useState([]);
  const [vecino, setVecino] = useState([]);
  const [preguntas, setPreguntas] = useState([]);
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [mostrarPreguntas, setMostrarPreguntas] = useState(false);
  const [gracias, setGracias] = useState(false);
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [casa, setCasa] = useState();
  const [codigo, setCodigo] = useState("");
  const [respuesta, setRespuesta] = useState("");
  const [digitos, setDigitos] = useState("");

  useMemo(() => {
    axios
      .get(URL_VECINOS_FIORI, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVecinosFiori = response.data;
        setVecinosFiori(allVecinosFiori);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_RESPUESTAS_FIORI}/responder/${vecino._id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPreguntas = response.data;
        let arrayPreguntas = allPreguntas
          .map((a) => {
            if (a.preguntasFiori[0].is_active == "Si") {
              return {
                _id: a._id,
                pregunta: a.preguntasFiori[0].pregunta,
                respuesta: a.respuesta,
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayPreguntas);

        setPreguntas(data);
      })
      .catch((err) => {
        console.log(err);
      });

    if (vecino.codigo == codigo) {
      setMostrarPreguntas(true);
    }
  }, [vecino, codigo]);

  function saveRespuestasFiori() {
    setValidaBoton(false);
    let totalVecinos = preguntas.length;

    preguntas.map((a) => {
      if(a.respuesta!="" && a.respuesta !="No ha votado"){
      axios
        .patch(
          `${URL_RESPUESTAS_FIORI}/${a._id}`,
          {
            fecha: hoy,
            respuesta: a.respuesta,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          totalVecinos = totalVecinos - 1;
          if (totalVecinos == 0) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Tu respuesta fue registrada",
              showConfirmButton: false,
              timer: 1500,
            });
            setMostrarPreguntas(false);
            setGracias(true);
            axios.post(
              URL_LOGS,
              {
                tipo: "Respuesta Blu",
                detalle: a.pregunta + " / " + a.respuesta,
                vecinosFiori: vecino._id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            );
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debes Seleccionar uan respuesta",
        });
        setValidaBoton(true);
      }
      //Cierra Map
    });
  }

  function jalaCasa(e) {
    setCasa(e.target.value);
    vecinosFiori.map((a) => {
      if (e.target.value == a.casa) {
        setVecino(a);
        setDigitos(a.codigo)
      }
    });
  }

  function revisaCodigo(e) {
    setCodigo(e.target.value);
    let d2 = digitos.length

    if (casa && d2 == e.target.value.length) {
      if (vecino.codigo == e.target.value) {
        setMostrarPreguntas(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El password es incorrecto!",
        });
      }
    }
  }

  const handleChangeInput = (id, event) => {
    const newPreguntas = preguntas.map((i) => {
      if (id === i._id) {
        i.respuesta = event.target.value;
      }
      return i;
    });
    setPreguntas(newPreguntas);
  };

  return (
    <>
      <header className="sticky-top">
        <Navbar color="dark" dark expand="lg">
          <img
            src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
            height="50 px"
            alt="logo"
          />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar></Nav>
          </Collapse>
        </Navbar>
      </header>
      <br />
      <br />
      <div className="container">
        <h3 align="center">VOTACIONES CONJUNTO BLU</h3>
        <Row>
          <Col md={2}>
            <Label>Casa</Label>
            <Input
              type="number"
              placeholder="Casa"
              value={casa}
              required
              onChange={(e) => {
                jalaCasa(e);
              }}
            />
          </Col>
          <Col md={2}>
            <Label>Password</Label>
            <Input
              type="password"
              placeholder="Codigo"
              value={codigo}
              required
              onChange={(e) => {
                revisaCodigo(e);
              }}
            />
          </Col>
        </Row>
        <br />
        <br />

        {mostrarPreguntas ? (
          <>
            <h3>Hola {vecino.nombre},</h3>
            <h3>Danos tu opinion sobre las siguientes preguntas</h3>
            <Table size="sm" striped borderless className="table-responsive-xl">
              <tr>
                <th>Pregunta</th>
                <th>Respuesta</th>
              </tr>
              <tbody>
                {preguntas.map((a) => {
                  return (
                    <tr>
                      <td>{a.pregunta}</td>
                      <td>
                        <Input
                          bsSize="sm"
                          type="select"
                          value={a.respuesta}
                          required
                          onChange={(e) => {
                            handleChangeInput(a._id, e);
                          }}
                        >
                          <option value="">Selecciona</option>
                          {/* <option value="Abstencion">Abstencion</option> */}
                          <option value="Si">Si</option>
                          <option value="No">No</option>
                        </Input>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <br />
            <Row>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  onClick={(e) => {
                    saveRespuestasFiori();
                  }}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
            </Row>
          </>
        ) : undefined}

        {gracias ? (
          <Col md={12}>
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              GRACIAS POR TU OPINION!
            </h1>
          </Col>
        ) : undefined}
      </div>
    </>
  );
}

export default RespuestasFioriCreate;
