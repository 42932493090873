import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";

function ListadoTickets() {
  const { user } = useContext(AuthContext);
  const URL_TICKETS = process.env.REACT_APP_URL_TICKETS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_MODULOS = process.env.REACT_APP_URL_MODULOS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-30);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [tickets, setTickets] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [modulos, setModulos] = useState([]);
  const [selectedModulo, setSelectedModulo] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [idEdit, setIdEdit] = useState("");
  const [fecha, setFecha] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [capturo, setCapturo] = useState("");
  const [solicito, setSolicito] = useState("");
  const [status, setStatus] = useState("");
  const [asignado, setAsignado] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [fechaResuelto, setFechaResuelto] = useState("");
  const [notificado, setNotificado] = useState("");

  const [selectedCapturo, setSelectedCapturo] = useState("");
  const [selectedAsignado, setSelectedAsignado] = useState("");
  const [selectedNotificado, setSelectedNotificado] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");


  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(()=>{
    axios
      .get(`${URL_TICKETS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTickets = response.data;
        // Array para pagination
        let arrayTabla = allTickets
          .sort((a, b) => (a.idTicket < b.idTicket ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idTicket,
              fecha: a.fecha,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              modulos: a.modulos[0].name,
              idModulos: a.modulos[0]._id,
              descripcion: a.descripcion,
              capturo: a.capturo,
              solicito: a.solicito,
              status: a.status,
              asignado: a.asignado,
              observaciones: a.observaciones,
              fechaResuelto: a.fechaResuelto,
              notificado: a.notificado,
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setTickets(data);
        setComments(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_MODULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allModulos = response.data;
        setModulos(allModulos);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [ selectedFechaInicio, selectedFechaFin]);



  function PDFTabla() {
    const data = comments
    .filter(
      (comment) =>
      comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
      comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
      comment.modulos.toLowerCase().includes(search.toLowerCase()) ||
      comment.descripcion.toLowerCase().includes(search.toLowerCase()) ||
      comment.capturo.toLowerCase().includes(search.toLowerCase()) ||
      comment.solicito.toLowerCase().includes(search.toLowerCase()) ||
      comment.status.toLowerCase().includes(search.toLowerCase()) ||
      comment.asignado.toLowerCase().includes(search.toLowerCase()) ||
      comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
      comment.notificado.toLowerCase().includes(search.toLowerCase()) ||
      comment.fechaResuelto.toLowerCase().includes(search.toLowerCase()) ||
      comment.numero.toString().includes(search) 
    )
    .sort((a, b) => (a.idTicket > b.idTicket ? 1 : -1))
    .map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedModulo == 0 || selectedModulo == a.idModulos) &&
        (selectedCapturo == 0 || selectedCapturo == a.capturo) &&
        (selectedAsignado == 0 || selectedAsignado == a.asignado) &&
        (selectedNotificado == 0 || selectedNotificado == a.notificado) &&
        (selectedStatus == 0 || selectedStatus == a.status)
      ) {
        return [
          a.numero,
          a.fecha,
          a.clientes,
          a.modulos,
          a.descripcion,
          a.capturo,
          a.solicito,
          a.status,
          a.asignado,
          a.observaciones,
          a.fechaResuelto,
          a.notificado,
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Tickets`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Cliente",
          "Modulo",
          "Descripcion",
          "Capturo",
          "Solicito",
          "Status",
          "Asignado",
          "Observaciones",
          "Fecha Resuelto",
          "Notificado",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
 
        ]
      ]
    });
    doc.save(`Tickets.pdf`);
  }

  function excel() {
    const dataExcel = comments
    .filter(
    (comment) =>
    comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
    comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
    comment.modulos.toLowerCase().includes(search.toLowerCase()) ||
    comment.descripcion.toLowerCase().includes(search.toLowerCase()) ||
    comment.capturo.toLowerCase().includes(search.toLowerCase()) ||
    comment.solicito.toLowerCase().includes(search.toLowerCase()) ||
    comment.status.toLowerCase().includes(search.toLowerCase()) ||
    comment.asignado.toLowerCase().includes(search.toLowerCase()) ||
    comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
    comment.notificado.toLowerCase().includes(search.toLowerCase()) ||
    comment.fechaResuelto.toLowerCase().includes(search.toLowerCase()) ||
    comment.numero.toString().includes(search) 
  )
  .sort((a, b) => (a.idTicket > b.idTicket ? 1 : -1))
  .map((a) => {
    if (
      (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
      (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
      (selectedModulo == 0 || selectedModulo == a.idModulos) 
      (selectedCapturo == 0 || selectedCapturo == a.capturo) &&
      (selectedAsignado == 0 || selectedAsignado == a.asignado) &&
      (selectedNotificado == 0 || selectedNotificado == a.notificado) &&
      (selectedStatus == 0 || selectedStatus == a.status)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Cliente: a.clientes,
          Modulo: a.modulos,
          Descripcion: a.descripcion,
          Capturo: a.capturo,
          Solicito: a.solicito,
          Status: a.status,
          Asignado: a.asignado,
          Observaciones: a.observaciones,
          FechaResuelto: a.fechaResuelto,
          Notificado: a.notificado,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoTicketsTiendas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoTicketsTiendas",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Cliente",
          "Modulo",
          "Descripcion",
          "Capturo",
          "Solicito",
          "Status",
          "Asignado",
          "Observaciones",
          "FechaResuelto",
          "Notificado"
          ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Cliente",
          "Modulo",
          "Descripcion",
          "Capturo",
          "Solicito",
          "Status",
          "Asignado",
          "Observaciones",
          "FechaResuelto",
          "Notificado"
          ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments
    .filter(
      (comment) =>
      comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
      comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
      comment.modulos.toLowerCase().includes(search.toLowerCase()) ||
      comment.descripcion.toLowerCase().includes(search.toLowerCase()) ||
      comment.capturo.toLowerCase().includes(search.toLowerCase()) ||
      comment.solicito.toLowerCase().includes(search.toLowerCase()) ||
      comment.status.toLowerCase().includes(search.toLowerCase()) ||
      comment.asignado.toLowerCase().includes(search.toLowerCase()) ||
      comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
      comment.notificado.toLowerCase().includes(search.toLowerCase()) ||
      comment.fechaResuelto.toLowerCase().includes(search.toLowerCase()) ||
      comment.numero.toString().includes(search) 
    )
    .sort((a, b) => (a.idTicket > b.idTicket ? 1 : -1))
    .map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedModulo == 0 || selectedModulo == a.idModulos) 
        (selectedCapturo == 0 || selectedCapturo == a.capturo) &&
        (selectedAsignado == 0 || selectedAsignado == a.asignado) &&
        (selectedNotificado == 0 || selectedNotificado == a.notificado) &&
        (selectedStatus == 0 || selectedStatus == a.status)
      ) {
        return [
          a.numero,
          a.fecha,
          a.clientes,
          a.modulos,
          a.descripcion,
          a.capturo,
          a.solicito,
          a.status,
          a.asignado,
          a.observaciones,
          a.fechaResuelto,
          a.notificado,
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Listado Tickets`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Cliente",
          "Modulo",
          "Descripcion",
          "Capturo",
          "Solicito",
          "Status",
          "Asignado",
          "Observaciones",
          "Fecha Resuelto",
          "Notificado",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
 
        ]
      ]
    });
    
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Tickets",
          email: mailTo,
          fileName: "ListadoTickets.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Tickets.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

 
  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Modulo", field: "modulo", sortable: true },
    { name: "Descripcion", field: "descripcion", sortable: true },
    { name: "Capturo", field: "capturo", sortable: true },
    { name: "Solicito", field: "solicito", sortable: true },
    { name: "Status", field: "status", sortable: true },
    { name: "Asignado", field: "descripcion", sortable: true },
    { name: "Observaciones", field: "observaciones", sortable: true },
    { name: "Fecha Resuelto", field: "fechaResuelto", sortable: true },
    { name: "Notificado", field: "notificado", sortable: true },
    { name: "Editar", field: "editar", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.modulos.toLowerCase().includes(search.toLowerCase()) ||
          comment.descripcion.toLowerCase().includes(search.toLowerCase()) ||
          comment.capturo.toLowerCase().includes(search.toLowerCase()) ||
          comment.solicito.toLowerCase().includes(search.toLowerCase()) ||
          comment.status.toLowerCase().includes(search.toLowerCase()) ||
          comment.asignado.toLowerCase().includes(search.toLowerCase()) ||
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.notificado.toLowerCase().includes(search.toLowerCase()) ||
          comment.fechaResuelto.toLowerCase().includes(search.toLowerCase()) ||
          comment.numero.toString().includes(search) 
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedModulo) {
      computedComments = computedComments.filter((e) =>
        e.idModulos.includes(selectedModulo)
      );
    }
    if (selectedCapturo) {
      computedComments = computedComments.filter((e) =>
        e.capturo.includes(selectedCapturo)
      );
    }
    if (selectedStatus) {
      computedComments = computedComments.filter((e) =>
        e.status.includes(selectedStatus)
      );
    }
    if (selectedAsignado) {
      computedComments = computedComments.filter((e) =>
        e.asignado.includes(selectedAsignado)
      );
    }
    if (selectedNotificado) {
      computedComments = computedComments.filter((e) =>
        e.notificado.includes(selectedNotificado)
      );
    }
    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }
    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "importe" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "importe" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "importe" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedModulo,
    selectedCapturo,
    selectedAsignado,
    selectedNotificado,
    selectedStatus,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  function jalaInfo(
    idEdit,
    fecha,
    descripcion,
    capturo,
    solicito,
    status,
    asignado,
    observaciones,
    fechaResuelto,
    notificado
  ) {
    setIdEdit(idEdit)
    setFecha(fecha)
    setDescripcion(descripcion)
    setCapturo(capturo)
    setSolicito(solicito)
    setStatus(status)
    setAsignado(asignado)
    setObservaciones(observaciones)
    setFechaResuelto(fechaResuelto)
    setNotificado(notificado)
    toggleEdit();
  }

  function editTicket(event) {
    event.preventDefault();
    const URL_TICKETS_EDIT = `${process.env.REACT_APP_URL_TICKETS}/${idEdit}`;
        axios.patch(
          URL_TICKETS_EDIT,
          {
            fecha,
            descripcion,
            capturo,
            solicito,
            status,
            asignado,
            observaciones,
            fechaResuelto,
            notificado
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Ticket",
            detalle: `${descripcion}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_gastos ? (
        <div className="card container col-sm-11">
          <Row>
            <Col md={8}>
              {user.menu_gastos ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/TicketsCreate"
                >
                  Nuevo Ticket
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Ticket
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuTickets"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Tickets</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Tickets</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>

          
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
              </td>
             
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedModulo}
                onChange={(e) => {
                  setSelectedModulo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                {modulos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.name}</option>;
                  })}
              </Input>
              </td>
              <td></td>
              <td>
              <Input
                    bsSize="sm"
                    type="select"
                    value={selectedCapturo}
                    onChange={(e) => {
                      setSelectedCapturo(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Jose">Jose</option>
                    <option value="Dany">Dany</option>
                    <option value="Sistemify">Sistemify</option>
                  </Input>
              </td>
              <td></td>
              <td>
              <Input
                    bsSize="sm"
                    type="select"
                    value={selectedStatus}
                    onChange={(e) => {
                      setSelectedStatus(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Abierto">Abierto</option>
                    <option value="Cerrado">Cerrado</option>
                  </Input>
              </td>
              <td>
                <Input
                    bsSize="sm"
                    type="select"
                    value={selectedAsignado}
                    onChange={(e) => {
                      setSelectedAsignado(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Jose">Jose</option>
                    <option value="Dany">Dany</option>
                    <option value="Sistemify">Sistemify</option>
                  </Input>
              </td>
              <td></td>
              <td></td>
              <td>
              <Input
                    bsSize="sm"
                    type="select"
                    value={selectedNotificado}
                    onChange={(e) => {
                      setSelectedNotificado(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="No">No</option>
                    <option value="Si">Si</option>
                  </Input>
              </td>

            </tr>
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.modulos}</td>
                      <td>{a.descripcion}</td>
                      <td>{a.capturo}</td>
                      <td>{a.solicito}</td>
                      <td>{a.status}</td>
                      <td>{a.asignado}</td>
                      <td>{a.observaciones}</td>
                      <td>{a.fechaResuelto}</td>
                      <td>{a.notificado}</td>
                      <td>
                      <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a._id,
                                  a.fecha,
                                  a.descripcion,
                                  a.capturo,
                                  a.solicito,
                                  a.status,
                                  a.asignado,
                                  a.observaciones,
                                  a.fechaResuelto,
                                  a.notificado
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />
      <Modal size="xl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Ticket</h4>
        </ModalHeader>
        <ModalBody>
        <Row>
                <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Fecha
                    </Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fecha}
                      required
                      onChange={(e) => setFecha(e.target.value)}
                    />
                  </Col>
                  {/* <Col md={4}>
                   <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      setValue(selectedCliente);
                    }}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => <TextField {...params} label="Selecciona" variant="outlined" />}
                    renderOption={(option) => (
                      <React.Fragment>
                    {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                  </Col>
                  <Col md={4}>
              <Label>Modulo</Label>
              <Input
                type="select"
                value={selectedModulo}
                onChange={(e) => {
                  setSelectedModulo(e.target.value)
                }}
              >
                <option value="">Selecciona un Modulo</option>
                {modulos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.name}
                      </option>
                    );
                  })}
              </Input>
            </Col> */}
                </Row>

            
                <Row>
                <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Descripcion
                    </Label>
                    <Input
                      type="text"
                      placeholder="Descripcion"
                      value={descripcion}
                      required
                      onChange={(e) => {
                        setDescripcion(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={4}>
                  <Label className="mr-sm-2">Capturo</Label>
                  <Input
                    type="select"
                    value={capturo}
                    onChange={(e) => {
                      setCapturo(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Jose">Jose</option>
                    <option value="Dany">Dany</option>
                    <option value="Sistemify">Sistemify</option>
                  </Input>
                  </Col>

                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                    Solicito
                    </Label>
                    <Input
                      type="text"
                      placeholder="Solicito"
                      value={solicito}
                      required
                      onChange={(e) => {
                        setSolicito(e.target.value);
                      }}
                    />
                  </Col>
                  
                </Row>

                <Row>
              <Col md={4}>
                  <Label className="mr-sm-2">Status</Label>
                  <Input
                    type="select"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Abierto">Abierto</option>
                    <option value="Cerrado">Cerrado</option>
                  </Input>
                  </Col>
                  <Col md={4}>
                  <Label className="mr-sm-2">Asignado</Label>
                  <Input
                    type="select"
                    value={asignado}
                    onChange={(e) => {
                      setAsignado(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Jose">Jose</option>
                    <option value="Dany">Dany</option>
                    <option value="Sistemify">Sistemify</option>
                  </Input>
                  </Col>
                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="text"
                      placeholder="Observaciones"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Fecha Resuelto
                    </Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fechaResuelto}
                      required
                      onChange={(e) => setFechaResuelto(e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                  <Label className="mr-sm-2">Notificado</Label>
                  <Input
                    type="select"
                    value={notificado}
                    onChange={(e) => {
                      setNotificado(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="No">No</option>
                    <option value="Si">Si</option>
                  </Input>
                  </Col>
                </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editTicket}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
     
      {loader}
    </>
  );
}

export default ListadoTickets;
