import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import RespuestasFioriCreate from "./RespuestasFioriCreate";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
  Badge,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

function ListadoRespuestasFiori() {
  const { user } = useContext(AuthContext);
  const URL_RESPUESTAS_FIORI = process.env.REACT_APP_URL_RESPUESTAS_FIORI;
  const URL_PREGUNTAS_FIORI = process.env.REACT_APP_URL_PREGUNTAS_FIORI;

  const [respuestasFiori, setRespuestasFiori] = useState([]);
  const [preguntasFiori, setPreguntasFiori] = useState([]);
  const [selectedPreguntas, setSelectedPreguntas] = useState("");
  const [selectedPreguntasName, setSelectedPreguntasName] = useState("");
  const [modal, setModal] = useState(false);

  const [votosSi, setVotosSi] = useState(0);
  const [votosNo, setVotosNo] = useState(0);
  const [votosAbs, setVotosAbs] = useState(0);

  useEffect(() => {
    axios
      .get(URL_RESPUESTAS_FIORI, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allRespuestasFiori = response.data;
        setRespuestasFiori(allRespuestasFiori);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PREGUNTAS_FIORI, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPreguntasFiori = response.data;
        setPreguntasFiori(allPreguntasFiori);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function selPregunta(e) {
    let v = e.target.value 
    let v1 = v.split(",")
    setSelectedPreguntas(v1[0]);
    setSelectedPreguntasName(v1[1]);

    let si = respuestasFiori.filter(
      (a) => a.preguntasFiori[0]._id == v1[0] && a.respuesta == "Si"
    );

    let NSi = si.length;
    setVotosSi(NSi);

    let No = respuestasFiori.filter(
      (a) => a.preguntasFiori[0]._id == v1[0] && a.respuesta == "No"
    );

    let NNo = No.length;
    setVotosNo(NNo);

    let Abs = respuestasFiori.filter(
      (a) =>
        a.preguntasFiori[0]._id == v1[0] && a.respuesta == "Abstencion"
    );

    let NAbs = Abs.length;
    setVotosAbs(NAbs);
  }

  let chart = am4core.create("chartdiv", am4charts.XYChart);
  chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

  chart.data = [
    {
      votos: "Si",
      cantidad: votosSi,
    },
    {
      votos: "No",
      cantidad: votosNo,
    },
    {
      votos: "Abs",
      cantidad: votosAbs,
    },
  ];

  let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.dataFields.category = "votos";
  categoryAxis.renderer.minGridDistance = 40;
  categoryAxis.fontSize = 11;

  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.min = 0;
  valueAxis.strictMinMax = true;
  valueAxis.renderer.minGridDistance = 30;


  let series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.categoryX = "votos";
  series.dataFields.valueY = "cantidad";
  series.columns.template.tooltipText = "{valueY.value}";
  series.columns.template.tooltipY = 0;
  series.columns.template.strokeOpacity = 0;
  
  var valueLabel = series.bullets.push(new am4charts.LabelBullet());
  valueLabel.label.text = "{valueY}";
  valueLabel.label.fontSize = 14;
  valueLabel.label.verticalCenter = "top"

  // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
  series.columns.template.adapter.add("fill", function (fill, target) {
    return chart.colors.getIndex(target.dataItem.index);
  });

  // chart.legend = new am4charts.Legend();
  chart.exporting.menu = new am4core.ExportMenu();
  chart.exporting.filePrefix = "myExport";

  let label = chart.createChild(am4core.Label);
label.text = selectedPreguntasName
label.fontSize = 14;
label.align = "center";

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
        {user.menu_logs ? (
          <div className="row">
            {
              <div className="col-md-6 col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h3 align="center">Respuestas</h3>
                    <Col>
                      <Label>Pregunta</Label>
                      <Input
                        bsSize="sm"
                        type="select"
                        onChange={(e) => {
                          selPregunta(e);
                        }}
                      >
                        <option value="">Selecciona</option>
                        {preguntasFiori
                          .sort((a, b) =>
                            a.idPregunta > b.idPregunta ? 1 : -1
                          )
                          .map((a) => {
                            return (
                              <option value={Object.values(a)}>
                                {a.idPregunta} / {a.pregunta}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <br />
                    <Row>
                      <Col md={4}>
                        <h4 align="center">
                          Si{"  "}
                          <Badge
                            id="Total"
                            color="success"
                            className="BadgeSize badge-pill"
                          >
                            {votosSi}
                          </Badge>
                        </h4>
                      </Col>
                      <Col md={4}>
                        <h4 align="center">
                          No{"  "}
                          <Badge
                            id="Total"
                            color="danger"
                            className="BadgeSize badge-pill"
                          >
                            {votosNo}
                          </Badge>
                        </h4>
                      </Col>
                      <Col md={4}>
                        <h4 align="center">
                          Abs{"  "}
                          <Badge
                            id="Total"
                            color="info"
                            className="BadgeSize badge-pill"
                          >
                            {votosAbs}
                          </Badge>
                        </h4>
                      </Col>
                    </Row>

                    <Table
                      size="sm"
                      striped
                      borderless
                      className="table-responsive-xl"
                    >
                      <thead>
                        <tr>
                          <th className="tituloTabla">Casa</th>
                          <th className="tituloTabla">Nombre</th>
                          <th className="tituloTabla">Respuesta</th>
                        </tr>
                      </thead>
                      <tbody>
                        {respuestasFiori
                          .sort((a, b) =>
                            a.vecinosFiori[0].casa > b.vecinosFiori[0].casa
                              ? 1
                              : -1
                          )
                          .map((a) => {
                            if (
                              a.preguntasFiori[0]._id == "" ||
                              a.preguntasFiori[0]._id == selectedPreguntas
                            ) {
                              return (
                                <tr>
                                  <td>{a.vecinosFiori[0].casa}</td>
                                  <td>
                                    {a.vecinosFiori[0].nombre}{" "}
                                    {a.vecinosFiori[0].apellido}
                                  </td>
                                  <td>{a.respuesta}</td>
                                </tr>
                              );
                            }
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            }
            <div className="col-md-6 col-sm-6">
              <div
                id="chartdiv"
                style={{ width: "100%", height: "400px", paddingTop: "10px" }}
              ></div>
            </div>
          </div>
        ) : undefined}
      </div>
    </>
  );
}

export default ListadoRespuestasFiori;
