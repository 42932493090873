import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function FacturasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SERIES = process.env.REACT_APP_URL_SERIES;
  const URL_TIMBRAR = process.env.REACT_APP_URL_TIMBRAR;
  const URL_FACTURAS = process.env.REACT_APP_URL_FACTURAS;
  const URL_CARGOS = process.env.REACT_APP_URL_CARGOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_EMPRESAS = process.env.REACT_APP_URL_EMPRESAS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  
  const [timbresDisponibles, setTimbresDisponibles] = useState(0);

  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalCant, setTotalCant] = useState(0);

  const [cargos, setCargos] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedEmpresa, setSelectedEmpresa] = useState("");

  const [cpEmpresa, setCpEmpresa] = useState("");
  const [rfcEmpresa, setRfcEmpresa] = useState("");
  const [nombreEmpresa, setNombreEmpresa] = useState("");
  const [regFiscalEmpresa, setRegFiscalEmpresa] = useState("");

  const [condicionesPagoSATCliente, setCondicionesPagoSATCliente] = useState("")
  const [formaPagoSATCliente, setFormaPagoSATCliente] = useState("")
  const [metodoPagoSATCliente, setMetodoPagoSATCliente] = useState("")
  const [rfcCliente, setRfcCliente] = useState("")
  const [nombreCliente, setNombreCliente] = useState("")
  const [usoCFDICliente, setUsoCFDICliente] = useState("")
  const [regFiscalCliente, setRegFiscalCliente] = useState("")
  const [cpCliente, setCpCliente] = useState("")

  const [serie, setSerie] = useState("S")

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
    .get(`${URL_CLIENTES}`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_SERIES}Timbres`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allTimbres = res.data;
          setTimbresDisponibles(allTimbres[0].folio);
        })
        .catch((err) => {
          console.log(err);
        });
     
      axios
      .get(`${URL_EMPRESAS}`, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allEmpresas = res.data;
          setEmpresas(allEmpresas);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  const saveFacturaNuevo = async (event) => {
    event.preventDefault();
    if(timbresDisponibles > 0){
    Swal.fire({
      title: "Estas seguro?",
      text: "Se guardará la Factura!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, guardar!",
    }).then(async (result) => {
    if (
      result.isConfirmed &&
      subTotal > 0 &&
      total > 0
    ) {
      setValidaBoton(false);
      toggleProgreso();
     
      let totalArticulos =
        inputFields.length 
      try {
        await axios
          .post(
            URL_FACTURAS,
            {
              fecha,
              observaciones,
              totalCantidad:1,
              subTotal: (subTotal).toFixed(2),
              iva: (iva).toFixed(2),
              ivaRet: 0,
              isr: 0,
              total: (total).toFixed(2),
              abonos:0,
              saldo: (total).toFixed(2),
              pagado:"No",
              descuentoPorcentaje1:0,
              descuentoNumero1:0,
              descuentoConcepto1:"NA",
              descuentoPorcentaje2:0,
              descuentoNumero2:0,
              descuentoConcepto2:"NA",
              empresas: selectedEmpresa,
              clientes: selectedCliente,
              timbre:"No",
              currency: "MXN",
              cfdiType:"I",
              
              serie,
              expeditionPlace: cpEmpresa,

              rfcEmpresa,
              nombreEmpresa,
              regFiscalEmpresa,

              condicionesPagoSATCliente,
              formaPagoSATCliente,
              metodoPagoSATCliente,
              rfcCliente,
              nombreCliente,
              usoCFDICliente,
              regFiscalCliente,
              cpCliente,

              numeroPagos:0,

            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {

            inputFields.map((w) => {
              axios
                .patch(
                  `${URL_CARGOS}Factura/${w.cargos}`,
                  {
                    facturas: data.data._id,
                    facturado: "Si",
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {

                    axios.post(`${URL_TIMBRAR}Factura`,{
                      facturas: data.data._id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    ).then(()=>{

                      axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Factura",
                          detalle: `Fecha Factura ${fecha} ${data.data.idFactura}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                        setModalProgreso(false)
                      });

                    }).catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                      setModalProgreso(false)
                    });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                  setModalProgreso(false)
                });
            });

          
          })
          // Catch primer then
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data.message}`,
            });
            console.log(error);
            setValidaBoton(true);
            setModalProgreso(false)
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          // footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true);
        setModalProgreso(false)
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
      setValidaBoton(true);
      setModalProgreso(false)
    }
  })
}else{
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Something went wrong!",
    footer: `No cuenta con timbres disponibles`,
  });
  setValidaBoton(true);
  setModalProgreso(false)
}
  };

  
  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        cargos: "",
        idCargo: "",
        total: 0,
      },
    ]);
  };


  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);

    let din = values.map((c) => parseFloat(c.total));
    let TD = din.reduce((t, total, index) => t + total, 0);
    setSubTotal(TD);

    let iva = (TD) * 0.16;
    setIva(iva);

    let total = (TD) + iva;
    setTotal(total);
  };


  function BuscaCliente(event) {
    setSelectedCliente(event.target.value)

    setInputFields(
      [{
        id: uuidv4(),
        cargos: "",
        idCargo: "",
        total: 0,
      }]
      );

    clientes.map((a)=>{
      if(a._id == event.target.value){
      setCondicionesPagoSATCliente(a.condicionesPagoSAT)
      setRfcCliente(a.RFC)
      setNombreCliente(a.razon_social)
      setUsoCFDICliente(a.usoCFDI)
      setRegFiscalCliente(a.regFiscal)
      setCpCliente(a.cp)

      if(a.metodoPagoSAT != "PPD"){
      setFormaPagoSATCliente(a.formaPagoSAT)
      setMetodoPagoSATCliente(a.metodoPagoSAT)
      }else{
        setFormaPagoSATCliente("99")
        setMetodoPagoSATCliente(a.metodoPagoSAT)
      }

      if(event.target.value != "" && selectedEmpresa != ""){
        axios
        .get(`${URL_CARGOS}Facturacion/${event.target.value}`, { 
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((res) => {
            const allCargos = res.data;
            let arrayTabla = allCargos.map((a)=>{
              return {
                id: uuidv4(),
                cargos: a._id,
                idCargo: a.idCargo,
                total: a.importe,
              }
            })
            let dataFinal = Object.values(arrayTabla);
            setCargos(dataFinal);
          })
          .catch((err) => {
            console.log(err);
          });
      }

    }
    })

  }

  function BuscaSurtido(id, event) {
    if (
      inputFields.filter((e) => e.cargos == event.target.value).length >
      0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El surtido ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.cargos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      cargos.map((a) => {
        if (a.cargos == event.target.value) {
          let cargos = a.cargos;
          let idCargo = a.idCargo;
          let total = a.total
          handleChangeInputArticulo(id, cargos, idCargo, total);
        }
      });
    }
  }

  const handleChangeInputArticulo = (id, cargos, idCargo, total) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cargos = cargos;
        i.idCargo = idCargo;
        i.total = total;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };


  function ActualizaTotales() {

    let din = inputFields.map((c) => parseFloat(c.total));
    let TD = din.reduce((t, total, index) => t + total, 0);
    setSubTotal(TD);

    let iva = (TD) * 0.16;
    setIva(iva);

    let total = (TD) + iva;
    setTotal(total);

  }


  function BuscaEmpresa(event) {
    setSelectedEmpresa(event.target.value)

    empresas.map((a)=>{
      if(a._id == event.target.value){
        setCpEmpresa(a.cp)
        setRfcEmpresa(a.rfc)
        setNombreEmpresa(a.razonSocial)
        setRegFiscalEmpresa(a.regimenFiscal)
      }
    })
    setInputFields(
    [{
      id: uuidv4(),
      surtidos: "",
      idSurtido: "",
      total: 0,
      totalCantidad: 0,
    }]
    );
  }


 

  return (
    <>
      <Header />
      <br />
      <br />
      {user.facturasFiscal ? (
        <div className="card container col-10">
          <h3 align="center">Nuevo Factura</h3>
          <Form onSubmit={saveFacturaNuevo}>
            <Row>
            <Col md={2}>
                <Label>Timbres Disponibles</Label>
                <Input
                  type="text"
                  value={timbresDisponibles}
                  required
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Label>Fecha Factura</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  disabled
                />
              </Col>
              <Col md={2}>
                <Label>Empresa</Label>
                    <Input
                      name="empresa"
                      type="select"
                      value={selectedEmpresa}
                      required
                      onChange={(event) => {
                        BuscaEmpresa(event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {empresas
                        .sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      )
                      .map((a) => {
                        if(a.listoFacturar == "Si" && a.is_active == "Si"){
                          return (
                            <option value={a._id}>{a.name}</option>
                          );}
                      })}
                    </Input>
                  </Col>

                  <Col md={2}>
                <Label>Cliente</Label>
                    <Input
                      type="select"
                      value={selectedCliente}
                      required
                      onChange={(event) => {
                        BuscaCliente(event);
                      }}
                    >
                      <option value="">Selecciona un Cliente</option>
                      {clientes
                        .sort((a, b) =>
                        a.nombre_comercial > b.nombre_comercial ? 1 : -1
                      )
                      .map((a) => {
                          return (
                            <option value={a._id}>{a.razon_social} / {a.nombre_comercial}</option>
                          );
                      })}
                    </Input>
                  </Col>

               
                  <Col md={2}>
                <Label>Serie</Label>
                    <Input
                      type="select"
                      value={serie}
                      required
                      onChange={(event) => {
                        setSerie(event.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="S">S</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                <Label>Forma Pago Fiscal</Label>
                <Input
                  type="select"
                  value={formaPagoSATCliente}
                  required
                  onChange={(e) => {
                    setFormaPagoSATCliente(e.target.value);
                  }}
                >
              <option value="">Selecciona</option>
              <option value="01"> 01 Efectivo</option>
              <option value="02"> 02 Cheque nominativo</option>
              <option value="03"> 03 Transferencia electrónica de fondos</option>
              <option value="04"> 04 Tarjeta de crédito</option>
              <option value="05"> 05 Monedero electrónico</option>
              <option value="06"> 06 Dinero electrónico</option>
              <option value="08"> 08 Vales de despensa</option>
              <option value="12"> 12 Dación en pago</option>
              <option value="13"> 13 Pago por subrogación</option>
              <option value="14"> 14 Pago por consignación</option>
              <option value="15"> 15 Condonación</option>
              <option value="17"> 17 Compensación</option>
              <option value="23"> 23 Novación</option>
              <option value="24"> 24 Confusión</option>
              <option value="25"> 25 Remisión de deuda</option>
              <option value="26"> 26 Prescripción o caducidad</option>
              <option value="27"> 27 A satisfacción del acreedor</option>
              <option value="28"> 28 Tarjeta de débito</option>
              <option value="29"> 29 Tarjeta de servicios</option>
              <option value="30"> 30 Aplicación de anticipos</option>
              <option value="99"> 99 Por definir</option>
                </Input>
              </Col>
                  </Row>
             <Row>
              <Col md={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Nota</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="cargos"
                      type="select"
                      value={inputField.cargos}
                      required
                      onChange={(event) => {
                        BuscaSurtido(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {cargos
                      .sort((a, b) => (a.idCargo > b.idCargo ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a.cargos}>
                            {a.idCargo} / {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.total)}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            <br />
            <Row>
            <Col>
            <Label className="mr-sm-2">Subtotal</Label>
            <Input
              type="number"
              value={subTotal}
              disabled
            />
            </Col>
            <Col>
            <Label className="mr-sm-2">IVA</Label>
            <Input
              type="number"
              value={iva}
              disabled
            />
            </Col>
            <Col>
            <Label className="mr-sm-2">Total</Label>
            <Input
              type="number"
              value={total}
              disabled
            />
            </Col>
            </Row>

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoFacturas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default FacturasCreate;
