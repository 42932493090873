import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Header from "../Header/Header";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { AuthContext } from "../../contexts/AuthContext";
import Footer from "../Footer/Footer";
import "./Login.css";
// import logo from "./Logo.png";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loginUser, user } = useContext(AuthContext);
  const history = useHistory();
  const [text, setText] = useState(false);

  function handleClick() {
    history.push("/Inicio");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const jsonSend = {
      email,
      password,
    };
    const LOGIN_URL = process.env.REACT_APP_URL_LOGIN;
    try {
      const res = await axios.post(LOGIN_URL, jsonSend);
      loginUser(res.data.token);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Bienvenido",
        showConfirmButton: false,
        timer: 2000,
      });
      handleClick();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  return (
    <>
      {/* <Header /> */}
      <div className="container col-sm-5">
        <div className="card login">
          <img
            src={process.env.PUBLIC_URL + "/Logo-Sistemify-Login.png"}
            height="10%"
            width="100%"
            alt="auto"
          />
          <div className="body">
            <Form className="container" onSubmit={handleSubmit}>
              <div className="row">
                <FormGroup className="col-sm-6">
                  <Label>Email</Label>
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="type your email"
                  />
                </FormGroup>
                <FormGroup className="col-sm-6">
                  <Label>Password</Label>
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="type your password here"
                  />
                </FormGroup>
              </div>
              <Button id="botonlogin" color="dany">
                Login
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
