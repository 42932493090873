import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Home from '../components/layout/Home/Home'
import Inicio from '../components/layout/Home/Inicio'
import Login from '../components/layout/Login/Login'
import ListadoUsuarios from '../components/layout/Login/ListadoUsuarios'
import Logout from '../components/layout/Login/Logout'
import AuthContextProvider from "./contexts/AuthContext.js";

// Logs
import Logs from './views/Logs/ListadoLogs'

// Clientes
import ListadoClientes from "./views/Clientes/ListadoClientes"
import ClientesCreate from "./views/Clientes/ClientesCreate"

// Cargos
import ListadoCargos from "./views/Cargos/ListadoCargos"
import CargosCreate from "./views/Cargos/CargosCreate"

// Abonos
import ListadoAbonos from "./views/Abonos/ListadoAbonos"
import AbonosCreate from "./views/Abonos/AbonosCreate"

// Mensualidades
import ListadoMensualidades from "./views/Mensualidades/ListadoMensualidades"
import MensualidadesCreate from "./views/Mensualidades/MensualidadesCreate"

// Estado Cuenta
import EstadoCuenta from "./views/EstadoCuenta/EstadoCuenta";

// Gastos
import BotonesGastos from "./views/Gastos/BotonesGastos"
import ListadoGastosGeneral from './views/Gastos/GastosGeneral/ListadoGastosGeneral'
import GastosGeneralCreate from './views/Gastos/GastosGeneral/GastosGeneralCreate'
import ListadoConceptosGastos from './views/Gastos/ConceptosGastos/ListadoConceptosGastos'
import ConceptosGastosCreate from './views/Gastos/ConceptosGastos/ConceptosGastosCreate'

// Tickets
import BotonesTickets from "./views/Tickets/BotonesTickets"
import ListadoModulos from './views/Tickets/Modulos/ListadoModulos'
import ListadoTickets from "./views/Tickets/Tickets/ListadoTickets"
import TicketsCreate from "./views/Tickets/Tickets/TicketsCreate"

//Reportes
import BotonesReportes from "./views/Reportes/BotonesReportes";
import GraficaVentasMensual from "./views/Reportes/GraficaVentasMensual";
import GraficaVentasAnual from "./views/Reportes/GraficaVentasAnual";
import GraficaAbonosMensual from "./views/Reportes/GraficaAbonosMensual";

// Retiros
import BotonesRetiros from "./views/Retiros/BotonesRetiros.jsx";
import ListadoSocios from "./views/Retiros/Socios/ListadoSocios.jsx";
import ListadoRetiros from "./views/Retiros/Retiros/ListadoRetiros.jsx";
import RetirosCreate from "./views/Retiros/Retiros/RetirosCreate.jsx";
import ListadoPorRetirar from "./views/Retiros/PorRetirar/ListadoPorRetirar.jsx";
import PorRetirarCreate from "./views/Retiros/PorRetirar/PorRetirarCreate.jsx";

import Whatsapp from "./views/WhatsApp/WhatsApp";

//Fiori
import BotonesFiori from "./views/Fiori/BotonesFiori";
import VecinosFioriCreate from "./views/Fiori/Vecinos/VecinosFioriCreate";
import ListadoVecinosFiori from "./views/Fiori/Vecinos/ListadoVecinosFiori";
import PreguntasFioriCreate from "./views/Fiori/Preguntas/PreguntasFioriCreate";
import ListadoPreguntasFiori from "./views/Fiori/Preguntas/ListadoPreguntasFiori";
import RespuestasFioriCreate from "./views/Fiori/Respuestas/RespuestasFioriCreate";
import ListadoRespuestasFiori from "./views/Fiori/Respuestas/ListadoRespuestasFiori";
import ListadoRespuestasFioriPublico from "./views/Fiori/Respuestas/ListadoRespuestasFioriPublico";

// Fiscal
import BotonesFiscal from "./views/Fiscal/BotonesFiscal";
import ListadoFacturas from "./views/Fiscal/Facturacion/ListadoFacturas.jsx";
import FacturasCreate from "./views/Fiscal/Facturacion/FacturasCreate.jsx";
import ListadoComplementosPago from "./views/Fiscal/ComplementosPago/ListadoComplementosPago.jsx";
import ComplementosPagoCreate from "./views/Fiscal/ComplementosPago/ComplementosPagoCreate.jsx";
import NotasCreditoCreate from "./views/Fiscal/NotasCredito/NotasCreditoCreate.jsx";
import ListadoNotasCredito from "./views/Fiscal/NotasCredito/ListadoNotasCredito.jsx";
import RegistrarEmpresas from "./views/Fiscal/RegistrarEmpresa/RegistrarEmpresas.jsx";
import ListadoPagosFacturas from "./views/Fiscal/ComplementosPago/ListadoPagosFacturas.jsx";
import EstadoCuentaK from "./views/Fiscal/EstadoCuentaK/EstadoCuentaK.jsx";
import ListadoEmpresas from "./views/Fiscal/Empresas/ListadoEmpresas";

function Routes() {
    return (
        <AuthContextProvider>
            <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route exact path ="/" component={Home} />
                <Route exact path ="/Inicio" component={Inicio} />
                <Route exact path ="/Login" component={Login} />
                <Route exact path ="/Logout" component={Logout} />
                <Route exact path ="/ListadoUsuarios" component={ListadoUsuarios} />
                {/* Logs  */}
                <Route exact path ="/MenuLogs" component={Logs} />
                 {/* Clientes */}
                 <Route exact path ="/ListadoClientes" component={ListadoClientes} />
                <Route exact path ="/ClientesCreate" component={ClientesCreate} />
                 {/* Cargos */}
                 <Route exact path ="/ListadoCargos" component={ListadoCargos} />
                <Route exact path ="/CargosCreate" component={CargosCreate} />
                {/* Abonos */}
                <Route exact path ="/ListadoAbonos" component={ListadoAbonos} />
                <Route exact path ="/AbonosCreate" component={AbonosCreate} />
                 {/* Mensualidades */}
                 <Route exact path ="/ListadoMensualidades" component={ListadoMensualidades} />
                <Route exact path ="/MensualidadesCreate" component={MensualidadesCreate} />
                {/* Edo Cta */}
                <Route exact path ="/EstadoCuenta" component={EstadoCuenta} />
                {/* Gastos */}
                <Route exact path ="/MenuGastos" component={BotonesGastos} />
                <Route exact path ="/Gastos" component={ListadoGastosGeneral} />
                <Route exact path ="/GastosCreate" component={GastosGeneralCreate} />
                <Route exact path ="/ConceptosGastos" component={ListadoConceptosGastos} />
                <Route exact path ="/conceptosGastosCreate" component={ConceptosGastosCreate} />
                {/* Tickets */}
                <Route exact path ="/MenuTickets" component={BotonesTickets} />
                <Route exact path ="/ListadoModulos" component={ListadoModulos} />
                <Route exact path ="/ListadoTickets" component={ListadoTickets} />
                <Route exact path ="/TicketsCreate" component={TicketsCreate} />
                 {/* Reportes */}
                 <Route exact path ="/MenuReportes" component={BotonesReportes} />
                <Route exact path ="/GraficaVentasMensual" component={GraficaVentasMensual} />
                <Route exact path ="/GraficaVentasAnual" component={GraficaVentasAnual} />
                <Route exact path ="/GraficaAbonosMensual" component={GraficaAbonosMensual} />

                {/* Retiros */}
                <Route exact path ="/MenuRetiros" component={BotonesRetiros} />
                <Route exact path ="/Socios" component={ListadoSocios} />
                <Route exact path ="/Retiros" component={ListadoRetiros} />
                <Route exact path ="/RetirosCreate" component={RetirosCreate} />
                <Route exact path ="/PorRetirar" component={ListadoPorRetirar} />
                <Route exact path ="/PorRetirarCreate" component={PorRetirarCreate} />
                <Route exact path ="/Whatsapp" component={Whatsapp} />

                {/* Fiori */}
                <Route exact path ="/MenuFiori" component={BotonesFiori} />
                <Route exact path ="/VecinosFiori" component={VecinosFioriCreate} />
                <Route exact path ="/ListadoVecinosFiori" component={ListadoVecinosFiori} />
                <Route exact path ="/PreguntasFiori" component={PreguntasFioriCreate} />
                <Route exact path ="/ListadoPreguntasFiori" component={ListadoPreguntasFiori} />
                <Route exact path ="/RespuestasFiori" component={RespuestasFioriCreate} />
                <Route exact path ="/ListadoRespuestasFiori" component={ListadoRespuestasFiori} />
                <Route exact path ="/RespuestasBlu" component={ListadoRespuestasFioriPublico} />
                <Route exact path ="/VotacionBlu" component={RespuestasFioriCreate} />

                {/* Fiscal */}
                <Route exact path ="/MenuFiscal" component={BotonesFiscal} />
                <Route exact path ="/ListadoFacturas" component={ListadoFacturas} />
                <Route exact path ="/FacturasCreate" component={FacturasCreate} />
                <Route exact path ="/ListadoComplementosPago" component={ListadoComplementosPago} />
                <Route exact path ="/ComplementosPagoCreate" component={ComplementosPagoCreate} />
                <Route exact path ="/ListadoNotasCredito" component={ListadoNotasCredito} />
                <Route exact path ="/NotasCreditoCreate" component={NotasCreditoCreate} />
                <Route exact path ="/RegistrarEmpresas" component={RegistrarEmpresas} />
                <Route exact path ="/ListadoPagosFacturas" component={ListadoPagosFacturas} />
                <Route exact path ="/EstadoCuentaK" component={EstadoCuentaK} />
                <Route exact path ="/ListadoEmpresas" component={ListadoEmpresas} />
            </Switch>
            </BrowserRouter>
            </AuthContextProvider>

    )
}

export default Routes
