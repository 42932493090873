import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Password from "../Login/Password";
import axios from "axios";

const Header = (props) => {
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleLogout = () => setDropdownOpen((prevState) => !prevState);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { isAuth, user } = useContext(AuthContext);
  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);
  const [status, setStatus] = useState("Esperando Estado...");

  useEffect(() => {
    axios
    .get(`${URL_WHATSAPP}Status`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allStatus = response.data.state;
      setStatus(allStatus);
    })
    .catch((err) => {
      console.log(err);
    });
  })
  return (
    <>
      {isAuth ? (
        <header className="sticky-top">
            <Navbar color="dark" dark expand="lg">
              <a href="/Inicio">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
              </a>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>

                   {user.menu_usuarios  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/ListadoClientes">
                        CLIENTES
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      CLIENTES
                    </NavLink>
                  )}

                    {user.menu_usuarios  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/ListadoCargos">
                        CARGOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      CARGOS
                    </NavLink>
                  )}

                  {user.menu_usuarios  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/ListadoAbonos">
                        ABONOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      ABONOS
                    </NavLink>
                  )}

                    {user.menu_usuarios  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/ListadoMensualidades">
                        MENSUALIDADES
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      MENSUALIDADES
                    </NavLink>
                  )}

                {user.menu_usuarios  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/EstadoCuenta">
                        ESTADO CUENTA
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      ESTADO CUENTA
                    </NavLink>
                  )}
                  
                  {user.menu_gastos  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuGastos">
                        GASTOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      GASTOS
                    </NavLink>
                  )}

                  {user.menu_gastos  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuRetiros">
                        RETIROS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      RETIROS
                    </NavLink>
                  )}

                {user.menu_gastos  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuFiscal">
                        FISCAL
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      FISCAL
                    </NavLink>
                  )}

                  {user.menu_gastos  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuReportes">
                        REPORTES
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      REPORTES
                    </NavLink>
                  )}


                  {user.menu_usuarios  ? (
                    <NavItem>
                      <NavLink style={{ color: status === 2 ? 'green' : 'red' }} href="/WhatsApp">
                        WHATSAPP
                      </NavLink>
                    </NavItem>

                  ) : (
                    <NavLink className="whatsapp"  disabled>
                      WHATSAPP
                    </NavLink>
                  )}


                  {user.menu_usuarios  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/ListadoUsuarios">
                        USUARIOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      USUARIOS
                    </NavLink>
                  )}
                  
                  {user.menu_logs  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuLogs">
                        LOGS
                      </NavLink>
                    </NavItem>
                  ) : undefined}

                  {/* {user.menu_logs  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuFiori">
                        FIORI
                      </NavLink>
                    </NavItem>
                  ) : undefined} */}
                </Nav>
                <NavbarText>
                  <NavLink className="titulosNav">
                    <Dropdown direction="left" isOpen={dropdownOpen} toggle={toggleLogout}>
                      <DropdownToggle color="link">
                        <i class="fas fa-user-cog fa-2x"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem href="/logout">Logout</DropdownItem>
                        <DropdownItem onClick={togglePassword}>Password</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </NavLink>
                </NavbarText>
                <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
                  <ModalHeader toggle={togglePassword}>
                    <h4>Cambio de contraseña</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Password />
                  </ModalBody>
                </Modal>
              </Collapse>
            </Navbar>
          
        </header>
      ) : undefined}
    </>
  );
};

export default Header;
