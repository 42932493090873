import React, { useState } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function BajaAlumno(props) {
  const [text, setText] = useState(false);
  const URL_BAJA = `${props.URL_BAJA}/${props.idStatus}`;

  function baja() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se dara de baja!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de Baja!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_BAJA,
            {
              is_active: "No",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se dio de Baja",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function alta() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se dara de Alta!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar de Alta!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_BAJA,
            {
              is_active: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se dio de Alta",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  return (
    <>
      {props.is_active == "Si" ? (
        <span>
        <Button
          color="danger"
          id="Baja"
          onClick={baja}
          size="sm"
        >
          <i class="far fa-times-circle"></i>
        </Button>
        </span>
      ) : (
        <span>
        <Button color="primary" onClick={alta} size="sm">
          <i class="far fa-check-circle"></i>
        </Button>
        </span>
      )}
    </>
  );
}

export default BajaAlumno;

