import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import VecinosFioriCreate from "./VecinosFioriCreate";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";

function ListadoVecinosFiori() {
  const { user } = useContext(AuthContext);
  const URL_VECINOS_FIORI = process.env.REACT_APP_URL_VECINOS_FIORI;

  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const [vecinosFiori, setVecinosFiori] = useState([]);
  const [modal, setModal] = useState(false);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [casa, setCasa] = useState(0);
  const [email, setEmail] = useState("");
  const [codigo, setCodigo] = useState("");

  const toggle = () => setModal(!modal);
  const [text, setText] = useState(false);

  const [idEdit, setIdEdit] = useState()

  useEffect(() => {
    axios
      .get(URL_VECINOS_FIORI, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVecinosFiori = response.data;
        setVecinosFiori(allVecinosFiori);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function editVecinosFiori(event) {
    event.preventDefault();
    const URL_VECINOS_FIORI_EDIT = `${process.env.REACT_APP_URL_VECINOS_FIORI}/${idEdit}`;
    axios
      .patch(
        URL_VECINOS_FIORI_EDIT,
        {
          nombre,
          apellido,
          casa,
          email,
          codigo
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Vecino Fiori',
            detalle: casa,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(idEdit, nombre, apellido, casa, email, codigo) {
        setNombre(nombre);
        setApellido(apellido)
        setCasa(casa)
        setEmail(email)
        setCodigo(codigo)
        setIdEdit(idEdit);
        toggle();
  }

  return (
    <>
      <Header />
      <br />
      <br />
      <div className="container">
        { user.menu_logs ? (
          <div className="row">
            {
              <div className="col-md-6 col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h3 align="center">Vecinos</h3>
                    <Table
                      size="sm"
                      striped
                      borderless
                      className="table-responsive-xl"
                    >
                      <thead>
                        <tr>
                          <th className="tituloTabla">Casa</th>
                          <th className="tituloTabla">Nombre</th>
                          <th className="tituloTabla">Email</th>
                          <th className="tituloTabla">Codigo</th>
                          <th className="tituloTabla">Editar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vecinosFiori
                          .sort((a, b) => (a.casa > b.casa ? 1 : -1))
                          .map((a) => {
                              return (
                                <tr>
                                  <td>{a.casa}</td>
                                  <td>{a.nombre}{" "}{a.apellido}</td>
                                  <td>{a.email}</td>
                                  <td>{a.codigo}</td>
                                  <td>
                                    <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) => jalaInfo(a._id, a.nombre, a.apellido, a.casa, a.email, a.codigo)}
                                    >
                                      <i class="far fa-edit"></i>{" "}
                                    </Button>

                                    <Baja
                                      idStatus={a._id}
                                      is_active={a.is_active}
                                      URL_BAJA={
                                        process.env.REACT_APP_URL_VECINOS_FIORI
                                      }
                                    />                               
                                   
                                  </td>
                                </tr>
                              );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            }
            <div className="col-md-6 col-sm-6">
              <div className="card">
                <div className="card-body">
                  <VecinosFioriCreate />
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
      </div>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar Vecino</ModalHeader>
        <ModalBody>
        <Label for="exampleEmail" className="mr-sm-2">
              Casa
            </Label>
            <Input
              type="number"
              placeholder="Casa"
              value={casa}
              required
              onChange={(e) => {
                setCasa(e.target.value);
              }}
            />
            <Label for="exampleEmail" className="mr-sm-2">
              Nombre
            </Label>
            <Input
              type="text"
              placeholder="Nombre"
              value={nombre}
              required
              onChange={(e) => {
                setNombre(e.target.value);
              }}
            />
            <Label for="exampleEmail" className="mr-sm-2">
              Apellido
            </Label>
            <Input
              type="text"
              placeholder="Apellido"
              value={apellido}
              required
              onChange={(e) => {
                setApellido(e.target.value);
              }}
            />
           <Label for="exampleEmail" className="mr-sm-2">
              Email
            </Label>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              required
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editVecinosFiori}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ListadoVecinosFiori;
