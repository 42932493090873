import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesTickets() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_gastos ?(
      <div className="container">
        <br />
        <br />
        <Row >
          <Col md={4} align="center">
            {user.menu_gastos ?(
            <Button href="/ListadoModulos" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              Modulos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              Modulos
            </Button> }
            </Col>

            <Col md={4} align="center">
            {user.menu_gastos ?(
            <Button href="/ListadoTickets" className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              Tickets
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-receipt fa-7x"></i>
              <br />
              <br />
              Tickets
            </Button> }
            </Col>
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesTickets;
