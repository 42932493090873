import React from 'react'
import './Footer.css'

function Footer() {
    return (
        <>
        <div id="footerColor">
        <h5 >Copyright</h5>
        </div>
        </>
    )
}

export default Footer
