import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";

const Signup = function () {
  const { user } = useContext(AuthContext);
  const URL_SIGNUP = process.env.REACT_APP_URL_USERS;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [text, setText] = useState(false);

  useEffect(() => {
 
},[]);

  const handleForm = (event) => {
    event.preventDefault();
    axios
      .post(
       URL_SIGNUP,
        {
          nombre: firstName,
          apellido: lastName,
          email,
          password,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  };

  const clear = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
  };



  return (
    <>
      <br />
      <br />
        <div className="card container">
          <h3 align="center">Registro de Usuarios</h3>
          <Form className="container" onSubmit={handleForm}>
              <Label>Nombre</Label>
              <Input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                id="firstName"
                name="inputFirstName"
                placeholder="Nombre"
              />
              <Label>Apellido</Label>
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                name="lastName"
                id="inputLastname"
                placeholder="Apellido"
              />
              <Label>Email</Label>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                id="exampleEmail"
                placeholder="Email"
              />
              <Label>Contraseña</Label>
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                name="password"
                id="examplePassword"
                placeholder="Contraseña"
              />
            {user.usuarios_create?(
            <Button type="submit" className="btn btn-success">
            Dar de Alta
          </Button>
          ): (
            <Button type="submit" className="btn btn-success" disabled>
            Dar de Alta
          </Button>
          ) }

          </Form>
      </div>
    </>
  );
};

export default Signup;
