import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import { TableHeader, Pagination, Search } from "../../DataTable";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../layout/Header/Header";
import Baja from "../Baja";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import useFullPageLoader from "../../hooks/useFullPageLoader";

function ListadoMensualidades() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_MENSUALIDADES = process.env.REACT_APP_URL_MENSUALIDADES;
  const URL_CARGOS = process.env.REACT_APP_URL_CARGOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;

  const [mensualidades, setMensualidades] = useState([]);
  const [frecuencia, setFrecuencia] = useState("");
  const [selectedFrecuencia, setSelectedFrecuencia] = useState("");
  const [selectedActivo, setSelectedActivo] = useState("");
  const [fecha, setFecha] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [clientes, setClientes] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalGenera, setModalGenera] = useState(false);
  const toggleGenera = () => setModalGenera(!modalGenera);
  const [text, setText] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  // const [activo, setActivo] = useState("");

  // const [modal, setModal] = useState(false);
  // const toggle = () => setModal(!modal);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [modalAumento, setModalAumento] = useState(false);
  const toggleAumento = () => setModalAumento(!modalAumento);
  const [idInfo, setIdInfo] = useState("");
  const [idCargo, setidCargo] = useState();
  const [nuevo_importe, setNuevoImporte] = useState();
  const [fecha_inicio, setFechaInicio] = useState();
  const [fecha_aumento, setFechaAumento] = useState();
  const [ultimo_aumento, setUltimoAumento] = useState();
  const [frecuenciaEdit, setFrecuenciaEdit] = useState();
  const [descripcionEdit, setDescripcionEdit] = useState();
  const [importe_anterior, setImporteAnterior] = useState();
  const [importe_actual, setImporteActual] = useState();
  const [selectedCliente, setSelectedCliente] = useState();
  const [selectedClienteName, setSelectedClienteName] = useState();

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_MENSUALIDADES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allMensualidades = response.data;
             // Array para pagination
             let arrayTabla = allMensualidades
             .sort((a, b) => (a.clientes[0].nombre_comercial > b.clientes[0].nombre_comercial ? 1 : -1))
             .map((a) => {
                 return {
                   _id: a._id,
                   is_active: a.is_active,
                   ultimo_aumento: a.ultimo_aumento,
                   importe_actual: a.importe_actual,
                   frecuencia: a.frecuencia,
                   descripcion: a.descripcion,
                   fecha_inicio: a.fecha_inicio,
                   idCliente: a.clientes[0]._id,
                   cliente: a.clientes[0].nombre_comercial,
                 }
             })
             .filter(function (el) {
               return el != null;
             });
   
           let dataFinal = Object.values(arrayTabla);
   
           setMensualidades(allMensualidades);
           setComments(dataFinal);
           //
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // PDF
  let totalImporte = 0;

  let tablaImporte = 0;
  let cantidad = 0;

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFrecuencia == 0 || selectedFrecuencia == a.frecuencia) &&
        (selectedActivo == 0 || selectedActivo == a.is_active)
      ) {
        const importe =
          "$" + new Intl.NumberFormat("en-US").format(a.importe_actual);
        totalImporte = totalImporte + a.importe_actual;
        return [
          a.cliente,
          a.fecha_inicio,
          a.frecuencia,
          importe,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(`Listado Mensualidades`, 15, 25);
    doc.autoTable({
      head: [
        [
          "Cliente",
          "Fecha Inicio",
          "Frecuencia",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalImporte),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Mensualidades.pdf`);
  }

  function generaMensualidades() {
    toggleGenera();
    Swal.fire({
      title: "Estas Seguro?",
      text: "Se generara un cargo a cada cliente",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, generar cargo",
    }).then((result) => {
      if (result.value) {
        toggleProgreso();
        let totalCargos = mensualidades.length;
        mensualidades.map((a) => {
          if (a.is_active == "Si" && frecuencia == a.frecuencia) {
            axios
              .post(
                URL_CARGOS,
                {
                  mensualidad: "Si",
                  fecha,
                  descripcion,
                  importe: a.importe_actual,
                  clientes: a.clientes[0]._id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalCargos = totalCargos - 1;
                if (totalCargos == 0) {
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          } else {
            totalCargos = totalCargos - 1;
          }
        });
      }
    });
  }
  const clear = () => {
    setFecha("");
    setDescripcion("");
    setFrecuencia("");
  };
  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFrecuencia == 0 || selectedFrecuencia == a.frecuencia) &&
        (selectedActivo == 0 || selectedActivo == a.is_active)
      ) {
        return {
          Cliente: a.cliente,
          Inicio: a.fecha_inicio,
          Frecuencia: a.frecuencia,
          Importe: a.importe_actual,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoMensualidades";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoMensualidades",
        sheetFilter: [
          "Cliente",
          "Inicio",
          "Frecuencia",
          "Importe",
        ],
        sheetHeader: [
          "Cliente",
          "Inicio",
          "Frecuencia",
          "Importe",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = mensualidades.map((a) => {
      if (
        (selectedFrecuencia == 0 || selectedFrecuencia == a.frecuencia) &&
        (selectedActivo == 0 || selectedActivo == a.is_active)
      ) {
        const importe =
          "$" + new Intl.NumberFormat("en-US").format(a.importe_actual);
        totalImporte = totalImporte + a.importe_actual;
        return [
          a.cliente,
          a.fecha_inicio,
          a.frecuencia,
          importe,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(`Listado Mensualidades`, 20, 25);
    doc.autoTable({
      head: [
        [
          "Cliente",
          "Fecha Inicio",
          "Frecuencia",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalImporte),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Resumen Mensualidades",
          email: mailTo,
          fileName: "ResumenMensualidades.pdf",
          att: base64File,
          text: `<!DOCTYPE html>
          <html>
            <head>
              <style>                                
                .img-container {
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                                height: 90px;
                                width: auto;
                              }
              </style>
            </head>
            <body>
              <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
              <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
              <h3>Hola,</h3>
              <h3>Adjunto encontraras el detalle de las mensualidades.</h3>
              <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
              Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
            </body>
          </html>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  function Info(id) {
    setIdInfo(id);
    toggle();
  }

  function jalaInfoEdit(idEdit) {
    const URL_MENSUALIDADES_EDIT = `${process.env.REACT_APP_URL_MENSUALIDADES}/${idEdit}`;
    axios
      .get(URL_MENSUALIDADES_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        setSelectedCliente(response.data.clientes[0]._id);
        setSelectedClienteName(response.data.clientes[0].nombre_comercial);
        setFechaInicio(response.data.fecha_inicio);
        setDescripcionEdit(response.data.descripcion);
        setidCargo(response.data._id);
        toggleEdit();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function editCargoMensual(event) {
    event.preventDefault();
    const URL_MENSUALIDADES_EDIT = `${process.env.REACT_APP_URL_MENSUALIDADES}/${idCargo}`;
    axios
      .patch(
        URL_MENSUALIDADES_EDIT,
        {
          fecha_inicio,
          descripcion: descripcionEdit,
          clientes: selectedCliente,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Mensualidad",
            detalle: `${selectedCliente}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  function jalaInfoAumento(idEdit) {
    const URL_MENSUALIDADES_EDIT = `${process.env.REACT_APP_URL_MENSUALIDADES}/${idEdit}`;
    axios
      .get(URL_MENSUALIDADES_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        setSelectedClienteName(response.data.clientes[0].nombre_comercial);
        setFechaInicio(response.data.fecha_inicio);
        setidCargo(response.data._id);
        setImporteAnterior(response.data.importe_anterior);
        setImporteActual(response.data.importe_actual);
        setUltimoAumento(response.data.ultimo_aumento);
        setFrecuenciaEdit(response.data.frecuencia);
        toggleAumento();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function aumentoCargoMensual(event) {
    event.preventDefault();
    const URL_MENSUALIDADES_EDIT = `${process.env.REACT_APP_URL_MENSUALIDADES}/${idCargo}`;
    axios
      .patch(
        URL_MENSUALIDADES_EDIT,
        {
          frecuencia: frecuenciaEdit,
          importe_actual: nuevo_importe,
          importe_anterior: importe_actual,
          ultimo_aumento: fecha_aumento,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleAumento();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Cliente", field: "cliente", sortable: true },
    { name: "Fecha Inicio", field: "fecha_inicio", sortable: true },
    { name: "Ultimo Aumento", field: "ultimo_aumento", sortable: true },
    { name: "Frecuencia", field: "Frecuencia", sortable: true },
    { name: "Importe", field: "importe_actual", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
          comment.frecuencia.toLowerCase().includes(search.toLowerCase()) ||
          comment.importe_actual.toString().includes(search)
          );
    }
    if (selectedActivo) {
      computedComments = computedComments.filter((e) =>
        e.is_active.includes(selectedActivo)
      );
    }

    if (selectedFrecuencia) {
      computedComments = computedComments.filter((e) =>
        e.frecuencia.includes(frecuencia)
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "importe"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "importe")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "importe")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedActivo,
    selectedFrecuencia
  ]);

  return (
    <>
      <Header />
      <br />
      <br />
      {user.usuarios_permisos ? (
        <div className="card container col-sm-11">
          <Row>
            <Col sm={8}>
              {user.usuarios_permisos ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/MensualidadesCreate"
                >
                  Nueva Mensualidad
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Mensualidad
                </Button>
              )}
              {/* {user.usuarios_permisos ? (
                <Button
                  size="sm"
                  onClick={toggleGenera}
                  className="btn btn-success"
                  id="botonListado"
                >
                  Generar Mensaulidades
                </Button>
              ) : (
                <Button
                  size="sm"
                  disabled
                  className="btn btn-success"
                  id="botonListado"
                >
                  Generar Mensaulidades
                </Button>
              )} */}


              <Button
                size="sm"
                href="/MenuCargos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          
          <h3 align="center">Mensualidades</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label for="exampleEmail" className="mr-sm-12">
                Frecuencia
              </Label>
              <Input
                bsSize="sm"
                type="select"
                placeholder="Frecuencia"
                value={selectedFrecuencia}
                required
                onChange={(e) => {
                  setSelectedFrecuencia(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                <option value="Mensual">Mensual</option>
                <option value="Anual">Anual</option>
              </Input>
            </Col>
            

            
            <Col md={2}>
              <Label for="exampleEmail" className="mr-sm-12">
                Activo
              </Label>
              <Input
                bsSize="sm"
                type="select"
                placeholder="Activo"
                value={selectedActivo}
                required
                onChange={(e) => {
                  setSelectedActivo(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Input>
            </Col>
          </Row>
          <Table size="sm" striped borderless className="table-responsive-xl">
          <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData
                .map((c) => {
                  tablaImporte = tablaImporte + c.importe_actual
                    return (
                      <tr>
                        <td>{c.cliente}</td>
                        <td>{c.fecha_inicio}</td>
                        <td>{c.ultimo_aumento}</td>
                        <td>{c.frecuencia}</td>
                        <td align="center">
                          {"$" +
                            new Intl.NumberFormat("en-US").format(
                              c.importe_actual
                            )}
                        </td>
                        <td>
                          {user.usuarios_permisos ? (
                            <div>
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                onClick={(e) => jalaInfoEdit(c._id)}
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                              <Button
                                color="primary"
                                id="Aumento"
                                size="sm"
                                onClick={(e) => jalaInfoAumento(c._id)}
                              >
                                <i class="fas fa-dollar-sign"></i>
                              </Button>
                              <Baja
                                idStatus={c._id}
                                is_active={c.is_active}
                                URL_BAJA={
                                  process.env
                                    .REACT_APP_URL_MENSUALIDADES
                                }
                              />
                            </div>
                          ) : (
                            <div>
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                disabled
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                              <Button
                                color="primary"
                                id="Aumento"
                                size="sm"
                                disabled
                              ></Button>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita" align="center">
                  {"$" + new Intl.NumberFormat("en-US").format(tablaImporte)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
            </div>
          </div>
        </div>
      ) : undefined}


<Modal size="sm" isOpen={modalGenera} toggle={toggleGenera}>
                <ModalHeader toggle={toggleGenera}>
                  Generar Mensualidades
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Fecha</Label>
                  <Input
                    className="col-sm-12"
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                  <Label for="exampleEmail" className="mr-sm-12">
                    Frecuencia
                  </Label>
                  <Input
                    type="select"
                    placeholder="Frecuencia"
                    value={frecuencia}
                    required
                    onChange={(e) => {
                      setFrecuencia(e.target.value);
                    }}
                  >
                    <option value="0">Frecuencia</option>
                    <option value="Mensual">Mensual</option>
                    <option value="Anual">Anual</option>
                  </Input>
                  <Label className="mr-sm-2">Descripcion</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={descripcion}
                    required
                    onChange={(e) => {
                      setDescripcion(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    size="sm"
                    color="success"
                    onClick={generaMensualidades}
                  >
                    Generar
                  </Button>
                </ModalFooter>
              </Modal>

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4> Editar Cargo de {selectedClienteName}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <Label className="mr-sm-2">Cliente</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedCliente}
                required
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                }}
              >
                <option value="0">Selecciona un Cliente</option>
                {clientes.map((c) => {
                  return (
                    <option value={c._id}>
                      {c.nombre_comercial}
                    </option>
                  );
                })}
              </Input>
            </Col>
            <Col md={6}>
              <Label>Fecha Inicio</Label>
              <Input
                type="date"
                value={fecha_inicio}
                required
                onChange={(e) => {
                  setFechaInicio(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Label className="mr-sm-2">Descripcion</Label>
          <Input
            className="col-sm-12"
            type="text"
            placeholder="Descripcion"
            value={descripcionEdit}
            required
            onChange={(e) => {
              setDescripcionEdit(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editCargoMensual}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalAumento} toggle={toggleAumento}>
        <ModalHeader toggle={toggleAumento}>
          <h4>Editar Cargo de {selectedClienteName} </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Label>
              Importe Anterior{" "}
              {"$" + new Intl.NumberFormat("en-US").format(importe_anterior)}{" "}
            </Label>
          </Row>
          <Row>
            <Label>Ultimo Aumento {ultimo_aumento}</Label>
          </Row>
          <Row>
            <Label className="rojoBold">
              Importe Actual{" "}
              {"$" + new Intl.NumberFormat("en-US").format(importe_actual)}{" "}
            </Label>
          </Row>

          <Row>
            <Label>Nuevo Importe</Label>
            <Input
              type="text"
              placeholder="Nuevo Importe"
              value={nuevo_importe}
              required
              onChange={(e) => {
                setNuevoImporte(e.target.value);
              }}
            />

            <Label>Fecha de Aumento</Label>
            <Input
              type="date"
              value={fecha_aumento}
              required
              onChange={(e) => {
                setFechaAumento(e.target.value);
              }}
            />

            <Label for="exampleEmail" className="mr-sm-12">
              Frecuencia
            </Label>
            <Input
              type="select"
              placeholder="Frecuencia"
              value={frecuenciaEdit}
              required
              onChange={(e) => {
                setFrecuencia(e.target.value);
              }}
            >
              <option value="0">Frecuencia</option>
              <option value="Quincenal">Quincenal</option>
              <option value="Mensual">Mensual</option>
              <option value="Bimestral">Bimestral</option>
              <option value="Trimestral">Trimestral</option>
              <option value="Semestral">Semestral</option>
              <option value="Anual">Anual</option>
            </Input>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={aumentoCargoMensual}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Resumen Mensualidades</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          Estamos generando los cargos, este proceso puede tardar varios
          minutos.
          <br />
          Por favor no cierre ni refresque su navegador.
          <br />
          <div className="divPadre2">
            <div className="divHijo2">
              <Spinner
                style={{ width: "3rem", height: "3rem", color: "#232B41" }}
              />
              <Spinner
                style={{ width: "3rem", height: "3rem", color: "#232B41" }}
              />
              <Spinner
                style={{ width: "3rem", height: "3rem", color: "#232B41" }}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoMensualidades;
