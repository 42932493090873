import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Get from "../../Get"

function TicketsCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_TICKETS = process.env.REACT_APP_URL_TICKETS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_MODULOS = process.env.REACT_APP_URL_MODULOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [fecha, setFecha] = useState(hoy);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [value, setValue] = useState('');
  const [modulos, setModulos] = useState([]);
  const [selectedModulo, setSelectedModulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [capturo, setCapturo] = useState("");
  const [solicito, setSolicito] = useState("");
  const [status, setStatus] = useState("");
  const [asignado, setAsignado] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [fechaResuelto, setFechaResuelto] = useState(hoy);
  const [notificado, setNotificado] = useState("");

  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_MODULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allModulos = res.data;
        setModulos(allModulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveTicket = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .all([
        axios.post(
          URL_TICKETS,
          {
            fecha,
            clientes: value._id,
            modulos: selectedModulo,
            descripcion,
            capturo,
            solicito,
            status,
            asignado,
            observaciones,
            fechaResuelto,
            notificado
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Ticket",
            detalle: `Fecha: ${fecha} / Clientes: ${value._id}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      });
  };

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option, junta
    };
  })

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_gastos ? (
        <div className="container">
          <div className="card container col-sm-12">
            <h3 align="center">Nuevo Ticket</h3>
            {/* <Get /> */}
            <Form onSubmit={saveTicket}>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Row>
                <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Fecha
                    </Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fecha}
                      required
                      onChange={(e) => setFecha(e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                   <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      setValue(selectedCliente);
                    }}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => <TextField {...params} label="Selecciona" variant="outlined" />}
                    renderOption={(option) => (
                      <React.Fragment>
                    {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                  </Col>
                  <Col md={4}>
              <Label>Modulo</Label>
              <Input
                type="select"
                value={selectedModulo}
                onChange={(e) => {
                  setSelectedModulo(e.target.value)
                }}
              >
                <option value="">Selecciona un Modulo</option>
                {modulos
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return (
                      <option value={a._id}>
                        {a.name}
                      </option>
                    );
                  })}
              </Input>
            </Col>
                </Row>

            
                <Row>
                <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Descripcion
                    </Label>
                    <Input
                      type="text"
                      placeholder="Descripcion"
                      value={descripcion}
                      required
                      onChange={(e) => {
                        setDescripcion(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={4}>
                  <Label className="mr-sm-2">Capturo</Label>
                  <Input
                    type="select"
                    value={capturo}
                    onChange={(e) => {
                      setCapturo(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Jose">Jose</option>
                    <option value="Dany">Dany</option>
                    <option value="Sistemify">Sistemify</option>
                  </Input>
                  </Col>

                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                    Solicito
                    </Label>
                    <Input
                      type="text"
                      placeholder="Solicito"
                      value={solicito}
                      required
                      onChange={(e) => {
                        setSolicito(e.target.value);
                      }}
                    />
                  </Col>
                  
                </Row>

                <Row>
              <Col md={4}>
                  <Label className="mr-sm-2">Status</Label>
                  <Input
                    type="select"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Abierto">Abierto</option>
                    <option value="Cerrado">Cerrado</option>
                  </Input>
                  </Col>
                  <Col md={4}>
                  <Label className="mr-sm-2">Asignado</Label>
                  <Input
                    type="select"
                    value={asignado}
                    onChange={(e) => {
                      setAsignado(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Jose">Jose</option>
                    <option value="Dany">Dany</option>
                    <option value="Sistemify">Sistemify</option>
                  </Input>
                  </Col>
                  <Col md={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Observaciones
                    </Label>
                    <Input
                      type="text"
                      placeholder="Observaciones"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                <Col sm={4}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Fecha Resuelto
                    </Label>
                    <Input
                      type="date"
                      placeholder="Fecha"
                      value={fechaResuelto}
                      required
                      onChange={(e) => setFechaResuelto(e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                  <Label className="mr-sm-2">Notificado</Label>
                  <Input
                    type="select"
                    value={notificado}
                    onChange={(e) => {
                      setNotificado(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="No">No</option>
                    <option value="Si">Si</option>
                  </Input>
                  </Col>
                </Row>

              </FormGroup>
              <br />
              <Col>
                <Row>
                  {validaBoton ? (
                    <Button type="submit" className="btn btn-success">
                      Guardar
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-success" disabled>
                      Guardar
                    </Button>
                  )}
                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                  <Button
                    href="/ListadoTickets"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Row>
              </Col>
            </Form>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default TicketsCreate;
