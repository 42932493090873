import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../layout/Header/Header";

function ClientesCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  // const [text, setText] = useState(false);

  const [nombre_comercial, setNombreComercial] = useState("NA");
  const [razon_social, setRazonSocial] = useState("NA");
  const [RFC, setRFC] = useState("NA");
  const [calle, setCalle] = useState("NA");
  const [numero_ext, setNumeroExt] = useState("NA");
  const [numero_int, setNumeroInt] = useState("NA");
  const [delegacion, setDelegacion] = useState("NA");
  const [estado, setEstado] = useState("NA");
  const [pais, setPais] = useState("Mexico");
  const [cp, setCP] = useState(0);
  const [colonia, setColonia] = useState("NA");
  const [ciudad, setCiudad] = useState("NA");
  const [contacto, setContacto] = useState("NA");
  const [telefono, setTelefono] = useState("0");
  const [email, setEmail] = useState("a@a.c");
  const [observaciones, setObservaciones] = useState("NA");
  const [validaBoton, setValidaBoton] = useState(true);

  const [lider, setLider] = useState("NA");
  const [ramo, setRamo] = useState("");
  const [copiadoDe, setCopiadoDe] = useState("");

  const [forma_pago, setFormaPago] = useState("");
  const [plazo, setPlazo] = useState("");
  const [regFiscal, setRegFiscal] = useState("");
  const [usoCFDI, setusoCFDI] = useState("");
  const [formaPagoSAT, setFormaPagoSAT] = useState("");
  const [condicionesPagoSAT, setCondicionesPagoSAT] = useState("");
  const [metodoPagoSAT, setMetodoPagoSAT] = useState("");


  const saveClientes = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    try {
      await axios
        .post(
          URL_CLIENTES,
          {
            nombre_comercial,
            razon_social,
            RFC,
            calle,
            numero_ext,
            numero_int,
            delegacion,
            estado,
            pais,
            cp,
            colonia,
            ciudad,
            contacto,
            telefono,
            email,
            observaciones,
            ultimo_pago: "2022-01-01",
            cargos: 0,
            abonos: 0,
            saldo: 0,
            lider,
            ramo,
            produccion:"No",
            copiadoDe,
            forma_pago,
            plazo,
            cargosk: 0,
            abonosk: 0,
            saldok: 0,
            usoCFDI,
            regFiscal,
            formaPagoSAT,
            condicionesPagoSAT,
            metodoPagoSAT,
            fiscal:"No",
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {/* {user.clientes_create ? ( */}
        <div className="card container">
          <h3 align="center">Nuevo Cliente</h3>
          <Form onSubmit={saveClientes}>
            <Row>
              <Col md={3}>
                <Label>Contacto</Label>
                <Input
                  type="text"
                  placeholder="Contacto"
                  value={contacto}
                  required
                  onChange={(e) => {
                    setContacto(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Nombre Comercial</Label>
                <Input
                  type="text"
                  placeholder="Nombre Comercial"
                  value={nombre_comercial}
                  required
                  onChange={(e) => {
                    setNombreComercial(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Razon Social</Label>
                <Input
                  type="text"
                  placeholder="Razon Social"
                  value={razon_social}
                  required
                  onChange={(e) => {
                    setRazonSocial(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>RFC</Label>
                <Input
                  type="text"
                  placeholder="RFC"
                  value={RFC}
                  required
                  onChange={(e) => {
                    setRFC(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Label>Calle</Label>
                <Input
                  type="text"
                  placeholder="Calle"
                  value={calle}
                  required
                  onChange={(e) => {
                    setCalle(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Ext</Label>
                <Input
                  type="text"
                  placeholder="No. Ext"
                  value={numero_ext}
                  required
                  onChange={(e) => {
                    setNumeroExt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>No. Int</Label>
                <Input
                  type="text"
                  placeholder="No. Int"
                  value={numero_int}
                  required
                  onChange={(e) => {
                    setNumeroInt(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Pais</Label>
                <Input
                  type="text"
                  placeholder="Pais"
                  value={pais}
                  required
                  onChange={(e) => {
                    setPais(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Estado</Label>
                <Input
                  type="text"
                  placeholder="Estado"
                  value={estado}
                  required
                  onChange={(e) => {
                    setEstado(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Ciudad</Label>
                <Input
                  type="text"
                  placeholder="Ciudad"
                  value={ciudad}
                  required
                  onChange={(e) => {
                    setCiudad(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Delegacion</Label>
                <Input
                  type="text"
                  placeholder="Delegacion o Municipio"
                  value={delegacion}
                  required
                  onChange={(e) => {
                    setDelegacion(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Colonia</Label>
                <Input
                  type="text"
                  placeholder="Colonia"
                  value={colonia}
                  required
                  onChange={(e) => {
                    setColonia(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
             
              <Col md={3}>
                <Label>CP</Label>
                <Input
                  type="text"
                  placeholder="CP"
                  value={cp}
                  required
                  onChange={(e) => {
                    setCP(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Telefono</Label>
                <Input
                  type="text"
                  placeholder="Telefono"
                  value={telefono}
                  required
                  onChange={(e) => {
                    setTelefono(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Email</Label>
                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Col>
              
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
            <Col md={3}>
                <Label>Lider</Label>
                <Input
                  type="text"
                  placeholder="Lider"
                  value={lider}
                  required
                  onChange={(e) => {
                    setLider(e.target.value);
                  }}
                />
              </Col>

              <Col md={3}>
                    <Label className="mr-sm-2">Ramo</Label>
                    <Input
                      type="select"
                      value={ramo}
                      required
                      onChange={(e) => {
                        setRamo(e.target.value);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="Despacho">Despacho</option>
                      <option value="Donativos">Donativos</option>
                      <option value="Importacion">Importacion</option>
                      <option value="Produccion">Produccion</option>
                      <option value="Sistema">Sistema</option>
                      <option value="Telas">Telas</option>
                    </Input>
                  </Col>

                  <Col md={3}>
                <Label>Copiado De</Label>
                <Input
                  type="text"
                  placeholder="Copiado de"
                  value={copiadoDe}
                  required
                  onChange={(e) => {
                    setCopiadoDe(e.target.value);
                  }}
                />
              </Col>

            </Row>

            <Row>
            <Col md={3}>
                <Label>Forma de Pago</Label>
                <Input
                  type="select"
                  placeholder="Forma de Pago"
                  value={forma_pago}
                  required
                  onChange={(e) => {
                    setFormaPago(e.target.value);
                  }}
                >
                  <option value="0">Forma de Pago</option>
                  <option value="Efectivo">Efectivo</option>
                  <option value="Deposito">Deposito</option>
                  <option value="Cheque">Cheque</option>
                  <option value="PorDefinir">Por Definir</option>
                </Input>
              </Col>
              <Col md={3}>
                <Label>Plazo</Label>
                <Input
                  type="select"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  onChange={(e) => {
                    setPlazo(e.target.value);
                  }}
                >
                  <option value="">Plazo</option>
                  <option value="0">0</option>
                  <option value="30">30</option>
                  <option value="60">60</option>
                  <option value="90">90</option>
                  <option value="120">120</option>
                  <option value="150">150</option>
                </Input>
              </Col>
            <Col md={3}>
                <Label>Regimen Fiscal</Label>
                <Input
                  type="select"
                  placeholder="RegFiscal"
                  value={regFiscal}
                  required
                  onChange={(e) => {
                    setRegFiscal(e.target.value);
                  }}
                >
                  <option value="">Reg Fiscal</option>
                  <option value="0">0</option>
                  <option value="601">601 General de Ley Personas Morales</option>
                  <option value="603">603 Personas Morales con Fines no Lucrativos</option>
                  <option value="605">605 Sueldos y Salarios e Ingresos Asimilados al Salario</option>
                  <option value="608">608 Demas Ingresos</option>
                  <option value="612">612 Personas Físicas con Actividades Empresariales y Profesionales</option>
                  <option value="616">616 Sin Obligacion Fiscal</option>
                  <option value="621">621 Incorporacion Fiscal</option>
                  <option value="624">624 Régimen de los Coordinados</option>
                  <option value="625">625 Reg. de las act. emp. cpn ingresos a traves de plat. tec.</option>
                  <option value="626">626 Regimen simplificado de Confianza</option>
                </Input>
              </Col>

              </Row>
            <Row>
              <Col md={3}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Uso de CDFI
                </Label>
                <Input
                  type="select"
                  placeholder="Uso de CDFI"
                  value={usoCFDI}
                  required
                  onChange={(e) => {
                    setusoCFDI(e.target.value);
                  }}
                >
                  <option value="">Uso de CDFI</option>
                  <option value="G01">G01 Adquisición de mercancías</option>
                  <option value="G03">G03 Gastos en general</option>
                  </Input>
              </Col>
 <Col md={3}>
                <Label>Forma Pago Fiscal</Label>
                <Input
                  type="select"
                  value={formaPagoSAT}
                  required
                  onChange={(e) => {
                    setFormaPagoSAT(e.target.value);
                  }}
                >
              <option value="">Selecciona</option>
              <option value="01"> 01 Efectivo</option>
              <option value="02"> 02 Cheque nominativo</option>
              <option value="03"> 03 Transferencia electrónica de fondos</option>
              <option value="04"> 04 Tarjeta de crédito</option>
              <option value="05"> 05 Monedero electrónico</option>
              <option value="06"> 06 Dinero electrónico</option>
              <option value="08"> 08 Vales de despensa</option>
              <option value="12"> 12 Dación en pago</option>
              <option value="13"> 13 Pago por subrogación</option>
              <option value="14"> 14 Pago por consignación</option>
              <option value="15"> 15 Condonación</option>
              <option value="17"> 17 Compensación</option>
              <option value="23"> 23 Novación</option>
              <option value="24"> 24 Confusión</option>
              <option value="25"> 25 Remisión de deuda</option>
              <option value="26"> 26 Prescripción o caducidad</option>
              <option value="27"> 27 A satisfacción del acreedor</option>
              <option value="28"> 28 Tarjeta de débito</option>
              <option value="29"> 29 Tarjeta de servicios</option>
              <option value="30"> 30 Aplicación de anticipos</option>
              <option value="99"> 99 Por definir</option>
                </Input>
              </Col>
              <Col md={3}>
                <Label>Metodo Pago Fiscal</Label>
                <Input
                  type="select"
                  value={metodoPagoSAT}
                  required
                  onChange={(e) => {
                    setMetodoPagoSAT(e.target.value);
                  }}
                >

              <option value="">Selecciona</option>
              <option value="PUE">PUE (Pago de Una Sola Exhibición)</option>
              <option value="PPD">PPD (Pago en Parcialidades o Diferido)</option>
                </Input>
              </Col>

              <Col md={3}>
                <Label>Condiciones de Pago Fiscal</Label>
                <Input
                  type="text"
                  value={condicionesPagoSAT}
                  required
                  onChange={(e) => {
                    setCondicionesPagoSAT(e.target.value);
                  }}
                />
              </Col>
              
            </Row>
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoClientes"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        </div>
      {/* ) : undefined */}
    </>
  );
}

export default ClientesCreate;
