import React, { useContext, useState, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Row, Input, Label, Col, Button, Badge, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import QRCode from "react-qr-code";
import Header from "../../layout/Header/Header";
import Baja from "../Baja";
function WhatsApp() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [qrData, setQRData] = useState("");
  const [status, setStatus] = useState("Esperando Estado...");

  

  setInterval(async () => {
    axios
      .get(`${URL_WHATSAPP}Status`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allStatus = response.data.state;
        setStatus(allStatus);
      })
      .catch((err) => {
        console.log(err);
      });
    }, 10000);

  const login = async (update) => {
    let qrCodeInterval;
    try {
      // Function to fetch and update the QR code
      const fetchQRCode = async (update) => {
        try {
          const response = await axios.get(`${URL_WHATSAPP}Login`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          });
          
          // Close the Swal here after receiving the response
          await Swal.close();

          if (response.status == 200) {
            if (!response.data.ready) {
              setQRData(response.data.qr);
              if (!update) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            }else{
              Swal.fire({
                position: "center",
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 2000,
              })
            }
          }



          return response.data.ready;
        } catch (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 2000,
          });
          console.log(err);
        }
      };

      // Show the loading message
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Generando QR",
        showConfirmButton: false,
      });

      // Initial fetch
      await fetchQRCode(update);

      // Set up a setInterval to fetch the QR code every 30 seconds
      qrCodeInterval = setInterval(async () => {
        const result = await fetchQRCode(true);
        if (result == true) {
          setQRData("");
          clearInterval(qrCodeInterval);
        }
      }, 10000);

      // You may want to store qrCodeInterval in a state or variable to be able to clear it later if needed.
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = async () => {
    try {
      await axios
        .post(
          `${URL_WHATSAPP}SendMsg`,
          {
            number: number,
            message: message,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.message == "Enviado") {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Tu mensaje ha sido enviado",
              showConfirmButton: false,
              timer: 2000,
            });
            setNumber("")
            setMessage("")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
    }
  };



  return (
    <>
      <Header />
      <br />
      <br />
      {user.usuarios_permisos ? (
        <div className="card container col-sm-11">
          <div className="row">
            <div className="col-md-8">
              <h3 align="center">WhatsApp</h3>
              <Row>
                <Col md={6}>
                <Row>
                <Col>
                {status == 2 ? (
                <Badge
                  color="info"
                  className="BadgeSize badge-pill">
                    CONNECTED
                  </Badge>
                  
                ):
                <Badge
                  color="danger"
                  className="BadgeSize badge-pill">
                    NOT CONNECTED
                  </Badge>
            }
                  </Col>
              </Row>
              <br />
              <Row>
                <Col>
                <h4>Enviar Mensaje de Prueba</h4>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label>Número de Teléfono:</Label>
                  <Input
                    type="number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </Col>
                <Col md={4}>
                  <Label>Mensaje:</Label>
                  <Input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Col>
              </Row>
              <br />

              <Button className="btn" color="success" onClick={sendMessage}>
                Enviar Mensaje
              </Button>

              <Button
                className="btn"
                color="info"
                onClick={(e) => login(false)}
              >
                Generar QR
              </Button>
              <br />
              <br />

              {qrData != "" ? (
                <Col md={6}>
                  <QRCode value={qrData} />
                </Col>
              ) : (
                ""
              )}
                </Col>

              </Row>

            </div>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default WhatsApp;
