import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesCompras() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_logs ?(
      <div className="container">
        <br />
        <br />
        <Row >
        <Col md={4} align="center">
              {user.menu_logs ? (
                <Button
                  href="/ListadoVecinosFiori"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Vecinos
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Vecinos
                </Button>
              )}
            </Col>

        <Col md={4} align="center">
          {user.menu_logs ?(
            <Button href="/ListadoPreguntasFiori" className="botonesMenu" color="success">
              <i class="fas fa-question fa-7x"></i>
              <br />
              <br />
              Preguntas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-question fa-7x"></i>
          <br />
          <br />
          Preguntas
        </Button> }
          </Col>

          <Col md={4} align="center">
          {user.menu_logs ?(
            <Button href="/ListadoRespuestasFiori" className="botonesMenu" color="success">
              <i class="fas fa-comment-dots fa-7x"></i>
              <br />
              <br />
              Respuestas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-comment-dots fa-7x"></i>
          <br />
          <br />
          Respuestas
        </Button> }
          </Col>

      

        
        
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesCompras;
